<template>
  <div>

    <v-alert
        type="error"
        v-if="errorMimeType"
    >
      Bestand is geen afbeelding. Toegestane bestandsextenties: jpg jpeg png gif.
    </v-alert>

    <v-btn block @click="openUploadDialog" depressed>
      {{ buttonText }}
    </v-btn>

    <v-dialog v-model="uploadDialog" width="auto">
      <v-card>
        <v-card-title>
          Upload een afbeelding
        </v-card-title>

        <v-card-text class="pa-4">
          <vue-dropzone :ref="dropzoneID" :id="dropzoneID" :options="dropzoneOptions" v-on:vdropzone-file-added="fileAdded"></vue-dropzone>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {uniqueId} from "lodash";

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    designUUID: {
      type: String,
      default: '',
    },
    dropzoneText: {
      type: String,
      default: 'Afbeelding <br>toevoegen',
    },
    showButtonInsteadOfDropzone: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Kies bestand',
    },
  },
  data: function () {
    return {
      dropzoneID: null,
      uploadDialog: false,
      errorMimeType: false,
    }
  },
  beforeMount() {
    this.dropzoneID = 'dropzone_'+uniqueId()
  },
  computed: {
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile
    },
    dropzoneOptions() {
     return {
       url: 'https://httpbin.org/post',
       autoProcessQueue: false,
       acceptedFiles: "image/*",
       thumbnailWidth: 150,
       thumbnailMethod: 'contain',
       dictDefaultMessage: this.dropzoneText,
       // maxFilesize: 0.5,
       // addRemoveLinks: true,
       // headers: { "My-Awesome-Header": "header value" }
     }
    }
  },
  methods: {
    showDialog() {
      // Show dialog
      this.uploadDialog = true
      // Reset errors
      this.errorMimeType = false
    },
    hideDialog() {
      this.uploadDialog = false
    },
    fileAdded(file) {
      // Make Vue 'this' accessible inside other functions
      let $vm = this;
      let reader = new FileReader();
      reader.onload = async function(event) {

        // Check if file is an image
        if (
            file.type === 'image/png' ||
            file.type === 'image/jpeg' ||
            file.type === 'image/gif' ||
            file.type === 'image/bmp'
        ) {
          // event.target.result contains base64 encoded image
          let base64String = event.target.result;

          let image = new Image();
          image.src = base64String;
          image.onload = function() {
            // Check if image is big enough
            if (image.width < 1000 || image.height < 1000) {
              $vm.$toast.warning("Let erop dat uw afbeelding of logo voldoende kwaliteit / grootte heeft. Raambanners.nl is niet aansprakelijk indien er een afbeelding geplaatst wordt die onvoldoende kwaliteit heeft of onscherp is.", {position: "bottom-center", hideProgressBar: false, timeout: 15000});
            }
          };

          // Upload file to backend
          const response = await $vm.uploadFile(file.name, base64String)
          // Show error when there is something wrong
          if (!response.ok) {
            $vm.$toast.error("Uw afbeelding kon niet geupload worden. Probeer het opnieuw. Foutcode: ["+response.status + "] "+response.statusText, {timeout: false});
            // Reset Dropzone uploader
            $vm.$refs[$vm.dropzoneID].removeAllFiles()
            return
          }
          /// Get JSON response
          const responseJson = await response.json()

          // Emit event back to parent
          $vm.$emit('image-uploaded', responseJson)
          // Reset Dropzone uploader
          $vm.$refs[$vm.dropzoneID].removeAllFiles()
          // Close dialog
          $vm.hideDialog()
        }
        else {
          // Uploaded file is not an image. Show error.
          $vm.errorMimeType = true
          // Reset Dropzone uploader
          $vm.$refs[$vm.dropzoneID].removeAllFiles()
        }

      };
      reader.readAsDataURL(file);
    },
    async uploadFile(filename, base64String) {
      // Upload file to backend
      const response = await fetch(process.env.VUE_APP_API_BASEURL+'api/design/'+this.designUUID+'/file-upload', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filename: filename,
          filebase64: base64String,
        })
      });
      return response
    },
    openFileBrowser() {
      this.$refs[this.dropzoneID].$el.click();
    },
    openUploadDialog() {
      this.showDialog()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dropzone {
  min-height: 300px;
  padding-top: 0;
  padding-bottom: 0;

  .dz-message {
    padding: 10em 0;
  }
}
</style>
