<template>
  <div>

    <v-bottom-navigation
        color="primary"
        app
        dark
        v-if="object && !isDesktop"
        v-model="activeTab"
    >
      <v-btn class="navigation-btn" value="position" v-if="!workspaceOneObjectEditing && (objectType === 'Text' || objectType === 'Image')">
        <span class="navigation-btn-text">Positie</span>
        <v-icon>mdi-ruler</v-icon>
      </v-btn>

      <v-btn class="navigation-btn" value="fontText" v-if="objectType === 'Text'">
        <span class="navigation-btn-text">Schrijven</span>
        <v-icon>mdi-keyboard-outline</v-icon>
      </v-btn>

      <v-btn class="navigation-btn" value="fontFamily" v-if="objectType === 'Text'">
        <span class="navigation-btn-text">Lettertype</span>
        <v-icon>mdi-format-text</v-icon>
      </v-btn>

      <v-btn class="navigation-btn" value="fontSize" v-if="objectType === 'Text'">
        <span class="navigation-btn-text">Grootte</span>
        <v-icon>mdi-format-size</v-icon>
      </v-btn>

      <v-btn class="navigation-btn" value="ownBackgroundImage" v-if="objectType === 'Background' && !workspaceOneObjectEditing">
        <span class="navigation-btn-text">Upload achtergrond</span>
        <v-icon>mdi-camera</v-icon>
      </v-btn>

      <v-btn class="navigation-btn" value="fill" v-if="objectType === 'Text' || objectType === 'Background'">
        <span class="navigation-btn-text">
          <template v-if="objectType === 'Background'">
            Effen achtergrond
          </template>
          <template v-else>
            Kleur
          </template>
        </span>
        <v-icon>mdi-format-color-fill</v-icon>
      </v-btn>

      <v-btn class="navigation-btn" value="defaultBackgroundImage" v-if="objectType === 'Background'">
        <span class="navigation-btn-text">Verloop achtergrond</span>
        <v-icon>mdi-image</v-icon>
      </v-btn>

      <v-btn class="navigation-btn" value="imageChange" v-if="objectType === 'Image'">
        <span class="navigation-btn-text">
            Afbeelding
        </span>
        <v-icon>mdi-camera</v-icon>
      </v-btn>

      <v-btn class="navigation-btn" value="remove" v-if="!workspaceOneObjectEditing && (objectType === 'Text' || objectType === 'Image')">
        <span class="navigation-btn-text">Verwijder</span>
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>


    <component :is="componentType" v-bind="componentProperties" style="transition: none;">

      <v-container fill-height style="padding: 0;">
        <v-row class="fill-height" no-gutters>
          <v-col cols="3" v-if="isDesktop">
            <attribute-editor-add-elements
                ref="attributeEditorAddElements"
                :designUUID="designUUID"
                :background-locked="backgroundLocked"
                :object="object"
                :workspace-one-object-editing="workspaceOneObjectEditing"
                @add-text-and-select="addTextAndSelect"
                @select-background="selectBackground"
                @create-temp-image-object-to-open-attribute-editor="createTempImageObjectToOpenAttributeEditor"
                class="attribute-add-elements-desktop fill-height"
            ></attribute-editor-add-elements>
          </v-col>
          <v-col cols="12" lg="9">

            <template v-if="object && object.getAttr('isBackground')">
              <v-form>
                <v-container>

                  <v-row v-if="(isDesktop || activeTab === 'ownBackgroundImage') && !workspaceOneObjectEditing">
                    <v-col>

                      <v-card flat :outlined="isDesktop">
                        <v-card-title v-if="isDesktop" class="text-subtitle-1">
                          Upload eigen ontwerp of achtergrond
                        </v-card-title>
                        <v-card-text>
                          <image-uploader ref="backgroundUploaderEdit" :designUUID="designUUID" key="image-uploader-background" :dropzone-text="'Upload hier uw eigen foto, logo of eigen ontwerp'" @image-uploaded="setOwnBackgroundImage"></image-uploader>
                          <v-btn small block @click="deleteBackgroundImage" depressed color="error" class="mt-3">
                            <v-icon left>
                              mdi-trash-can-outline
                            </v-icon>
                            Achtergrond afbeelding verwijderen
                          </v-btn>
                        </v-card-text>
                      </v-card>

                    </v-col>
                  </v-row>
                  <v-row v-if="isDesktop || activeTab === 'fill'">
                    <v-col>
                      <v-card flat :outlined="isDesktop">
                        <v-card-title v-if="isDesktop">
                          Kies effen achtergrond
                        </v-card-title>
                        <v-card-text>
                          <v-swatches
                              background-color="transparant"
                              v-model="backgroundColor"
                              inline
                              swatch-size="24"
                              :swatches="swatches"
                              row-length="6"
                          ></v-swatches>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-if="isDesktop || activeTab === 'defaultBackgroundImage'">
                    <v-col>

                      <v-card flat :outlined="isDesktop">
                        <v-card-title v-if="isDesktop">
                          Kies achtergrond met verloop
                        </v-card-title>
                        <v-card-text>

                          <div class="choose-default-background">
                              <div class="default-background-thumbnails">
                                <div v-for="(background) in backgrounds" v-bind:key="background.url" class="select-option">
                                  <v-card
                                      outlined
                                      @click="setDefaultBackgroundImage(background)"
                                      v-bind:class="{active: background.url === selectedBackgroundID}"
                                      width="25"
                                  >
                                    <v-card-text class="pa-0">
                                      <img v-if="background.url" :src="require(`../assets/default-backgrounds/thumbnails/${background.url}`)" class="default-background-thumbnail"  alt=""/>
                                    </v-card-text>
                                  </v-card>
                                </div>
                              </div>
                          </div>

                        </v-card-text>
                      </v-card>

                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
            <template v-else>

              <div v-if="!object" class="pt-lg-2" style="width: 100%;">

<!--                <p class="text-caption">Klik op een tekst of afbeelding om hem te bewerken.</p>-->

                <div class="pt-2" v-if="!isDesktop">
                  <attribute-editor-add-elements
                      v-if="!workspaceOneObjectEditing"
                      :designUUID="designUUID"
                      :background-locked="backgroundLocked"
                      :object="object"
                      @add-text-and-select="addTextAndSelect"
                      @select-background="selectBackground"
                      @create-temp-image-object-to-open-attribute-editor="createTempImageObjectToOpenAttributeEditor"
                  ></attribute-editor-add-elements>
                </div>
              </div>
              <template v-else>

                <v-form style="width: 100%">

                  <v-container fluid>

                    <template v-if="objectType === 'Text'">
                      <v-row v-if="isDesktop || activeTab === 'fontText'" justify="center">
                        <v-col
                            cols="12"
                            md="12"
                        >
                          <v-card min-height="0" flat :outlined="isDesktop" class="attribute-editor-form-block">
                            <v-card-title v-if="isDesktop">
                              Uw tekst
                            </v-card-title>
                            <div class="attribute-editor-form-block-content pt-0">
                              <v-textarea
                                  ref="text"
                                  v-model="text"
                                  required
                                  rows="1"
                                  row-height="28"
                                  auto-grow
                                  filled
                                  hide-details
                                  @focus="$event.target.select()"
                                  @input="updateObject"
                              ></v-textarea>

                              <v-btn block @click="addTextAndSelect" depressed class="mt-3" v-if="!workspaceOneObjectEditing">
                                <v-icon left>
                                  mdi-format-text
                                </v-icon>
                                Extra tekst toevoegen
                              </v-btn>

                            </div>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-card min-height="0" flat :outlined="isDesktop" class="attribute-editor-form-block">
                        <v-card-title v-if="isDesktop">
                          Tekst opmaak
                        </v-card-title>
                        <div class="attribute-editor-form-block-content pt-0">

                          <v-row v-if="isDesktop || activeTab === 'fontSize'" justify="center">
                            <v-card
                                flat
                                color="transparent"
                                width="100%"
                                class="px-3"
                            >
                              <v-subheader class="px-0">Tekstgrootte</v-subheader>
                              <v-slider
                                  v-model="scale"
                                  max="1500"
                                  min="20"
                                  :thumb-size="24"
                                  thumb-label="always"
                                  thumb-color="primary"
                                  append-icon="mdi-plus-circle"
                                  prepend-icon="mdi-minus-circle"
                                  @click:append="increaseScale"
                                  @click:prepend="decreaseScale"
                                  @input="updateObject"
                              ></v-slider>
                            </v-card>
                          </v-row>
                          <v-row v-if="isDesktop || activeTab === 'fontFamily'" justify="center" class="mt-0">
                            <v-col
                                cols="12"
                                md="12"
                            >
                              <v-select
                                  v-model="fontFamily"
                                  :items="[
                    { text: 'Arial', value: 'Arial'},
                    { text: 'Amatic SC', value: 'Amatic SC'},
                    { text: 'Big Shoulders Stencil Text', value: 'Big Shoulders Stencil Text'},
                    { text: 'Cabin Sketch', value: 'Cabin Sketch'},
                    { text: 'Caudex', value: 'Caudex'},
                    { text: 'Fredoka', value: 'Fredoka'},
                    { text: 'Goldman', value: 'Goldman'},
                    { text: 'Grandstander', value: 'Grandstander'},
                    { text: 'Kalam', value: 'Kalam'},
                    { text: 'Lato', value: 'Lato'},
                    { text: 'Montserrat', value: 'Montserrat'},
                    { text: 'Oleo Script', value: 'Oleo Script'},
                    { text: 'PT Serif', value: 'PT Serif'},
                    { text: 'Roboto', value: 'Roboto'},
                    { text: 'Roboto Mono', value: 'Roboto Mono'},
                    { text: 'Roboto Slab', value: 'Roboto Slab'},
                ]"
                                  label="Lettertype"
                                  filled
                                  hide-details
                                  v-on:change="updateObject"
                              >
                                <template v-slot:selection="{ item }">
                                  <span :style="{ 'font-family': item.value, 'font-size': '21px' }">{{ item.text }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                  <span :style="{ 'font-family': item.value, 'font-size': '21px' }">{{ item.text }}</span>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row class="text-left mt-6" dense justify="center" v-if="isDesktop || activeTab === 'fontFamily'">
                            <v-col>
                              <v-btn-toggle multiple v-model="textStyle" v-on:change="updateObject">

                                <v-btn value="bold">
                                  <v-icon>mdi-format-bold</v-icon>
                                </v-btn>

                                <v-btn value="italic">
                                  <v-icon>mdi-format-italic</v-icon>
                                </v-btn>

                                <v-btn value="underline">
                                  <v-icon>mdi-format-underline</v-icon>
                                </v-btn>

                              </v-btn-toggle>

                              <!--                  <v-btn value="underline">-->
                              <!--                    <v-icon>mdi-format-underline</v-icon>-->
                              <!--                  </v-btn>-->
                            </v-col>
                            <v-col>
                              <v-btn-toggle mandatory v-model="textAlign" v-on:change="updateObject">
                                <v-btn value="left">
                                  <v-icon>mdi-format-align-left</v-icon>
                                </v-btn>

                                <v-btn value="center">
                                  <v-icon>mdi-format-align-center</v-icon>
                                </v-btn>

                                <v-btn value="right">
                                  <v-icon>mdi-format-align-right</v-icon>
                                </v-btn>
                              </v-btn-toggle>
                            </v-col>
                          </v-row>
                          <v-row v-if="isDesktop || activeTab === 'fill'" justify="center"  align="center" class="mt-0">
                            <v-col>

                              <v-card
                                  flat
                                  color="transparent"
                                  width="100%"
                              >
                                <v-subheader class="pt-0 px-0" v-if="isDesktop">Tekstkleur</v-subheader>
                                <v-swatches
                                    background-color="transparant"
                                    v-model="fill"
                                    inline
                                    swatch-size="25"
                                    :swatches="swatches"
                                    row-length="6"
                                ></v-swatches>
                              </v-card>

                            </v-col>
                          </v-row>

                        </div>
                      </v-card>

                    </template>
                    <template v-else-if="objectType === 'Image'">
                      <v-row v-if="isDesktop || activeTab === 'imageChange'" justify="center">
                        <v-col
                            cols="12"
                            md="12"
                        >

                          <!-- Use v-show instead of v-if. We need the button to be present because we open the upload dialog with a $ref via this button. -->
                          <v-card min-height="0" v-show="!workspaceOneObjectEditing" flat :outlined="isDesktop" class="attribute-editor-form-block" :key="object.getAttr('isNewImage') ? 'image-uploader-image-add' : 'image-uploader-image-update'">
                            <v-card-title v-if="isDesktop">
                              <template v-if="object.getAttr('isNewImage')">
                                Afbeelding toevoegen
                              </template>
                              <template v-else>
                                Afbeelding wijzigen
                              </template>
                            </v-card-title>
                            <div class="attribute-editor-form-block-content">

                                <!-- Edit selected image -->
                                <image-uploader ref="imageUploaderEdit" :designUUID="designUUID" :dropzone-text="'Klik hier en upload uw foto, logo, of kant-en-klaar ontwerp. Verschuif uw beeld met <img width=\'20\' height=\'20\' src=\''+require(`../assets/icon-move.png`)+'\' /> '" @image-uploaded="loadAndUpdateExistingImage"></image-uploader>

                                <!-- Add new image -->
                                <image-uploader class="mt-4" :designUUID="designUUID" show-button-instead-of-dropzone dropzone-text="Upload hier uw eigen foto, logo of eigen ontwerp" button-text="Extra afbeelding toevoegen" @image-uploaded="loadAndAddNewImage"></image-uploader>

<!--                                <v-alert-->
<!--                                    dense-->
<!--                                    outlined-->
<!--                                    prominent-->
<!--                                    type="warning"-->
<!--                                    class="mt-3"-->
<!--                                >-->
<!--                                  Let op: Achtergrond(foto) toevoegen doet u <v-btn outlined x-small color="warning" @click="selectBackground">hier</v-btn>-->
<!--                                </v-alert>-->

                                <template v-if="userIsAdmin">
                                  <v-checkbox
                                      v-model="isLocked"
                                      label="Afbeelding vergrendeld"
                                      on-icon="mdi-lock"
                                      off-icon="mdi-lock-open-variant-outline"
                                  ></v-checkbox>
                                  <v-subheader class="pt-0 px-0 text-lg-left" v-if="isDesktop">Als de afbeelding vergrendeld is kan deze niet door een gebruiker aangepast of geselecteerd worden.</v-subheader>
                                </template>

                              <v-select
                                v-model="clipPosition"
                                label="Bijsnijden"
                                hint="Vanuit welk punt wilt u dat de afbeelding wordt afgesneden?"
                                persistent-hint
                                :items="clipPositionOptions"
                              ></v-select>

                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>

                    <v-card
                        v-if="isDesktop || (activeTab === 'fill' || activeTab === 'imageChange')"
                        flat
                        color="tranparent"
                        width="100%"
                        class="attribute-editor-form-block"
                    >
                      <v-card-title v-if="isDesktop">
                        <template v-if="activeTab === 'imageChange'">
                          Randkleur afbeelding
                        </template>
                        <template v-else>
                          Randkleur
                        </template>
                      </v-card-title>
                      <div class="attribute-editor-form-block-content">
                        <v-swatches
                            background-color="transparant"
                            v-model="stroke"
                            inline
                            swatch-size="25"
                            :swatches="swatchesStroke"
                            row-length="6"
                        ></v-swatches>
                      </div>

                      <template v-if="stroke">
                        <v-card-title v-if="isDesktop">
                          Randdikte
                        </v-card-title>
                        <div class="attribute-editor-form-block-content">
                            <v-slider
                                v-model="strokeWidth"
                                max="30"
                                min="1"
                                step="0.1"
                                :thumb-size="24"
                                thumb-label="always"
                                thumb-color="primary"
                                append-icon="mdi-plus-circle"
                                prepend-icon="mdi-minus-circle"
                                @click:append="increaseStrokeWidth"
                                @click:prepend="decreaseStrokeWidth"
                                @input="updateObject"
                            ></v-slider>
                        </div>
                      </template>

                    </v-card>

                    <v-card v-if="!workspaceOneObjectEditing && isDesktop || activeTab === 'position'" min-height="0" flat :outlined="isDesktop" class="attribute-editor-form-block">
                      <div class="attribute-editor-form-block-content">

                        <v-row justify="center">
                          <v-col cols="12">
                            <v-btn block @click="moveUpObject" depressed>
                              <v-icon left>
                                mdi-arrange-bring-forward
                              </v-icon>
                              {{ objectTypeTranslated }} naar voorgrond
                            </v-btn>
                            <v-btn block @click="moveDownObject" depressed class="mt-1">
                              <v-icon left>
                                mdi-arrange-send-backward
                              </v-icon>
                              {{ objectTypeTranslated }} naar achtergrond
                            </v-btn>
                          </v-col>
                        </v-row>

                      </div>
                    </v-card>

                    <v-card v-if="!workspaceOneObjectEditing && isDesktop || activeTab === 'remove'" min-height="0" flat :outlined="isDesktop" class="attribute-editor-form-block">
                      <div class="attribute-editor-form-block-content">

                        <v-row justify="center">
                          <v-col>
                            <v-btn block @click="deleteObject" depressed color="error">
                              <v-icon left>
                                mdi-trash-can-outline
                              </v-icon>
                              Verwijder {{ objectTypeTranslated }}
                            </v-btn>
                          </v-col>
                        </v-row>

                      </div>
                    </v-card>

                  </v-container>

                </v-form>

              </template>

            </template>

            <div v-if="isDesktop" class="pa-4">
              <v-btn
                  href="#"
                  color="success"
                  v-if="isDesktop"
                  :large="isDesktop"
                  :block="isDesktop"
                  depressed
                  @click="openProductEditor"
              >
                <span>Verder</span>
                <v-icon small>mdi-arrow-right</v-icon>
              </v-btn>
            </div>

          </v-col>
        </v-row>
      </v-container>

    </component>

  </div>
</template>

<script>
import {VFooter, VNavigationDrawer} from 'vuetify/lib'
import imageUploader from "@/components/imageUploader";
import VSwatches from 'vue-swatches'
import attributeEditorAddElements from "@/components/attributeEditorAddElements";
// Import the styles too, globally
import "vue-swatches/dist/vue-swatches.css"

export default {
  name: 'attributeEditor',
  components: {
    imageUploader,
    VFooter,
    VNavigationDrawer,
    VSwatches,
    attributeEditorAddElements,
  },
  props: {
    object: {
      type: Object,
      default: function () {
        return null
      }
    },
    designUUID: {
      type: String,
      default: '',
    },
    backgroundLocked: {
      type: Boolean,
      default: false,
    },
    workspaceOneObjectEditing: {
      type: Boolean,
      default: false,
    },
    userIsAdmin: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      activeTab: '',
      showColorpicker: false,
      x: 0,
      y: 0,
      rotation: 0,
      text: '',
      fontSize: 48,
      scale: 100,
      fontFamily: '',
      textAlign: null,
      textStyle: [],
      fill: '#FFFFFF',
      stroke: '',
      strokeWidth: 0,
      backgroundColor: '#FFFFFF',
      swatches: ['#1FBC9C','#1CA085','#2ECC70','#27AF60','#3398DB','#2980B9','#A463BF','#8E43AD','#3D556E','#222F3D','#F2C511','#F39C19', '#E84B3C', '#C0382B', {color: '#FFFFFF', showBorder: true}, '#DDE6E8', '#BDC3C8', '#000000'],
      swatchesStroke: ['', '#1FBC9C','#1CA085','#2ECC70','#27AF60','#3398DB','#2980B9','#A463BF','#8E43AD','#3D556E','#222F3D','#F2C511','#F39C19', '#E84B3C', '#C0382B', {color: '#FFFFFF', showBorder: true}, '#DDE6E8', '#BDC3C8', '#000000'],
      backgrounds: [
        { url: 'background-gradient-linear-01-v01.png', },
        { url: 'background-gradient-linear-02-v01.png', },
        { url: 'background-gradient-linear-03-v01.png', },
        { url: 'background-gradient-linear-04-v01.png', },
        { url: 'background-gradient-linear-05-v01.png', },
        { url: 'background-gradient-linear-06-v01.png', },
        { url: 'background-gradient-linear-07-v01.png', },
        { url: 'background-gradient-linear-08-v01.png', },
        { url: 'background-gradient-linear-09-v01.png', },
        { url: 'background-gradient-radial-01-v01.png', },
        { url: 'background-gradient-radial-02-v01.png', },
        { url: 'background-gradient-radial-03-v01.png', },
        { url: 'background-gradient-radial-04-v01.png', },
        { url: 'background-gradient-radial-05-v01.png', },
        { url: 'background-gradient-radial-06-v01.png', },
        { url: 'background-gradient-radial-07-v01.png', },
        { url: 'background-gradient-radial-08-v01.png', },
        { url: 'background-gradient-radial-09-v01.png', },
      ],
      selectedBackgroundID: null,
      isNewImage: false,
      isLocked: false,
      clipPosition: 'center-middle',
      clipPositionOptions: [
        { text: 'Midden', value: 'center-middle'},
        { text: 'Links boven', value: 'left-top'},
        { text: 'Rechts boven', value: 'right-top'},
        { text: 'Rechts onder', value: 'right-bottom'},
        { text: 'Links onder', value: 'left-bottom'},
      ],
    }
  },
  computed: {
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile
    },
    componentType() {
      if (this.isDesktop) {
        return 'VNavigationDrawer'
      }
      else {
        return 'VFooter'
      }
    },
    componentProperties() {
      if (this.isDesktop) {
        return {
          color: "rgba(240,240,240,1)",
          app: true,
          width: 500,
          clipped: true,
        }
      }
      else {
        return {
          app: true,
          dark: true,
        }
      }
    },
    objectType() {
      if (this.object.getAttr('isBackground')) {
        return 'Background'
      }
      return this.object.getClassName()
    },
    objectTypeTranslated() {
      switch (this.objectType) {
        case 'Text':
          return 'Tekst'
        case 'Image':
          return 'Afbeelding'
        case 'Background':
          return 'Achtergrond'
      }
      return 'Toevoegen'
    },
  },
  watch: {
    object: function() {
      this.selectedObjectHasChanged()
    },
    fill: function() {
      this.updateObject()
    },
    stroke: function() {
      this.updateObject()
    },
    backgroundColor: function() {
      // Remove the background image
      this.deleteBackgroundImage()
      // Update background color
      this.updateBackground()
    },
    isLocked: function() {
      this.updateObject()
    },
    clipPosition: function() {
      this.updateObject()
    },
  },
  created() {
    this.selectedObjectHasChanged()
  },
  methods: {
    selectedObjectHasChanged() {
      // Bind event listener
      let $vm = this;

      if (this.object) {
        // Set values when object prop changes
        $vm.setValues()
        // Set the selected tab
        if ($vm.objectType === 'Text') {
          // Select 'fontText' tab
          $vm.activeTab = 'fontText'
        }
        else if ($vm.objectType === 'Image') {
          // Select 'imageChange' tab
          $vm.activeTab = 'imageChange'
        }
        else {
          // Select first tab
          $vm.activeTab = ''
        }

        // Unbind previous events (if any)
        this.object.off('dragend transformend')
        // Set values when object is dragged or transformed
        this.object.on('dragend transformend', function () {
          $vm.setValues()
        });

        // Auto focus and select text
        if (this.isDesktop && this.objectType === 'Text') {
          $vm.$nextTick(function(){
            $vm.$refs.text.focus()
          })
        }

      }
    },
    increaseScale() {
      this.scale++
      this.updateObject()
    },
    decreaseScale() {
      this.scale--
      this.updateObject()
    },
    increaseStrokeWidth() {
      this.strokeWidth += 0.1
      this.updateObject()
    },
    decreaseStrokeWidth() {
      this.strokeWidth -= 0.1
      this.updateObject()
    },
    setValues() {
      let object = this.object
      if (this.object) {
        this.x = object.x()
        this.y = object.y()
        this.rotation = object.rotation()

        // Get stroke properties
        if (this.objectType === 'Text' || this.objectType === 'Image') {
          this.stroke = object.stroke()
          this.strokeWidth = object.strokeWidth()
        }

        if (this.objectType === 'Image') {
          this.isLocked = object.getAttr('isLocked')
          this.clipPosition = object.getAttr('clipPosition')
        }

        // Set text properties
        if (this.objectType === 'Text') {
          this.text = object.text()
          this.fontSize = object.fontSize()
          this.scale = object.scaleX() * 100
          this.fontFamily = object.fontFamily()
          this.textAlign = object.align()
          this.textStyle = object.fontStyle().split(' ')
          // Add text decoration
          if (object.textDecoration() === 'underline') {
            this.textStyle.push('underline')
          }
          // Get hex color
          this.fill = object.fill()
        }
        else if (this.objectType === 'Background') {
          // Get RGBA string
          let fill = object.fill()
          if (fill) {
            // Set color as object
            this.fill = fill
          }
        }
      }
    },
    updateObject() {

      // Get variables, and set proper default values when empty
      let x = this.x ? parseFloat(this.x) : 0
      let y = this.y ? parseFloat(this.y) : 0
      let rotation = this.rotation ? parseFloat(this.rotation) : 0

      // Set default attributes
      let attributes = {
        type: this.objectType,
        id: this.object.attrs.id,
        x: x,
        y: y,
        rotation: rotation,
      }

      if (this.objectType === 'Text') {
        // Set text attributes
        attributes.text = this.text ? this.text : 'Uw tekst'
        attributes.fontSize = this.fontSize
        attributes.scaleX = this.scale/100
        attributes.scaleY = this.scale/100
        attributes.fontFamily = this.fontFamily
        attributes.textAlign = this.textAlign
        // Set text style, default to 'normal' when not set
        if (this.textStyle.length) {
          let textStyles = []
          if (this.textStyle.includes('bold')) {
            textStyles.push('bold')
          }
          if (this.textStyle.includes('italic')) {
            textStyles.push('italic')
          }
          // Set text style
          attributes.textStyle = textStyles.join(' ')
          // Set text decoration
          if (this.textStyle.includes('underline')) {
            attributes.textDecoration = 'underline'
          }
        }
        else {
          attributes.textStyle = 'normal'
          attributes.textDecoration = ''
        }
        // Set color
        attributes.fill = this.fill
      }

      // Set stroke properties
      if (this.objectType === 'Text' || this.objectType === 'Image') {
        // Set color
        attributes.stroke = this.stroke
        attributes.strokeWidth = this.strokeWidth
      }

      if (this.objectType === 'Image') {
        attributes.isLocked = this.isLocked
        attributes.clipPosition = this.clipPosition
      }

      // else if (this.objectType === 'Image') {
      //
      // }

      // Emit event back to parent
      this.$emit('object-updated', attributes)

    },
    deleteObject() {
      this.$emit('object-destroy', this.object.attrs.id)
    },
    deleteBackgroundImage() {
      this.$emit('delete-background-image')
    },
    moveUpObject() {
      this.$emit('object-move-up', this.object.attrs.id)
    },
    moveDownObject() {
      this.$emit('object-move-down', this.object.attrs.id)
    },
    updateBackground() {
      let attributes = {
        type: this.objectType,
        id: this.object.attrs.id,
        fill: this.backgroundColor
      }
      // Emit event back to parent
      this.$emit('background-updated', attributes)
    },
    addTextAndSelect() {
      // Emit event back to parent
      this.$emit('add-text-and-select')
    },
    selectBackground() {
      // Emit event back to parent
      this.$emit('select-background')
    },
    setOwnBackgroundImage(image) {
      // Deselect default background ID
      this.selectedBackgroundID = null
      // Emit event back to parent
      this.$emit('set-background-image', image)
    },
    setDefaultBackgroundImage(image) {
      // Set selected background ID
      this.selectedBackgroundID = image.url
      // Create image object with the full resolution image
      let imageEmit = {}
      imageEmit.fid = null
      imageEmit.url = require(`../assets/default-backgrounds/${image.url}`)
      // Emit event back to parent
      this.$emit('set-background-image', imageEmit)
    },
    loadAndAddNewImage(image) {
      // Emit event back to parent
      this.$emit('load-and-add-new-image', image)
    },
    loadAndUpdateExistingImage(image) {
      // Emit event back to parent
      this.$emit('load-and-update-existing-image', {
        image: image,
        id: this.object.attrs.id,
      })
    },
    createTempImageObjectToOpenAttributeEditor() {
      // Emit event back to parent
      this.$emit('create-temp-image-object-to-open-attribute-editor')
    },
    openUploadDialogEditImage() {
      let $vm = this;
      // Wait for the Vue component to be mounted with nextTick, then open the dialog
      this.$nextTick(function(){
        // Open the dialog
        $vm.$refs['imageUploaderEdit'].openUploadDialog();
      })
    },
    openUploadDialogEditBackground() {
      let $vm = this;
      // Wait for the Vue component to be mounted with nextTick, then open the dialog
      this.$nextTick(function(){
        // Open the dialog
        $vm.$refs['backgroundUploaderEdit'].openUploadDialog();
      })
    },
    openProductEditor() {
      this.$emit('open-product-editor');
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.navigation-btn {
  min-width: 40px !important;

  .navigation-btn-text {
    font-size: 11px;
  }
}
.attribute-editor-form-block {
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    background: #fff;
    margin-bottom: 20px;
  }
  .attribute-editor-form-block-content {
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      padding: 15px;
    }
  }
}
.attribute-add-elements-desktop {
  background: rgba(240,240,240,1);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px;
}
.v-navigation-drawer__border {
  display: none;
}
.default-background-thumbnails {
  //@media #{map-get($display-breakpoints, 'md-and-down')} {
  //  overflow-x: auto;
  //  white-space: nowrap;
  //  flex-wrap: nowrap !important;
  //  display: flex;
  //}

  .select-option {
    display: inline-block;
    margin-right: 7px;

    img {
      border-radius: 5px;
    }
  }

  .default-background-thumbnail {
    display: block;
    height: auto;
    width: 100%;
  }
}
.vue-swatches__diagonal {
  background: none;
}
.vue-swatches__diagonal::after {
  content: "\00d7"; /* This will render the 'X' */
  font-size: 40px;
  color: red;
  height: 75%;
  width: 75%;
  display: block;
  position: absolute;
  top: -18px;
  left: -1px;
  //background: linear-gradient(to top left,transparent 0,transparent calc(50% - 2.4px),#de080a 50%,transparent calc(50% + 2.4px),transparent);
}
</style>
