import Vue from 'vue'
import App from './App.vue'
import store from './plugins/store';
import vuetify from './plugins/vuetify'
import toastification from './plugins/toastification';
import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.prototype.$selectedObjects = []

new Vue({
  store,
  vuetify,
  toastification,
  render: h => h(App)
}).$mount('#app')
