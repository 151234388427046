<template>
   <div class="attribute-add-elements">
     <span v-if="!backgroundLocked" class="btn-add-object btn-add-object-background" @click="selectBackground">
     <v-btn color="primary" :small="!isDesktop" depressed fab :class="objectType === 'Background' ? 'active' : ''">
      <v-icon>
        mdi-image
      </v-icon>
    </v-btn>
      <div class="text-caption">Achtergrond <br>wijzigen</div>
    </span>

     <span class="btn-add-object btn-add-object-text " @click="addTextAndSelect" v-if="!workspaceOneObjectEditing">
      <v-btn color="primary" depressed :small="!isDesktop" fab :class="objectType === 'Text' ? 'btn-edit-text active' : 'btn-edit-text'">
        <v-icon>
          mdi-format-text
        </v-icon>
      </v-btn>
      <div class="text-caption">Tekst <br>toevoegen</div>
    </span>

     <span class="btn-add-object btn-add-object-image" @click="createTempImageObjectToOpenAttributeEditor" v-if="!workspaceOneObjectEditing">
      <v-btn color="primary" depressed :small="!isDesktop" fab :class="objectType === 'Image' ? 'btn-edit-image active' : 'btn-edit-image'">
        <v-icon>
          mdi-image
        </v-icon>
      </v-btn>
      <div class="text-caption">Afbeelding <br>toevoegen</div>
    </span>

   </div>
</template>

<script>
export default {
  name: 'attributeEditorAddElements',
  props: {
    designUUID: {
      type: String,
      default: '',
    },
    backgroundLocked: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      default: function () {
        return null
      }
    },
    workspaceOneObjectEditing: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      activeTab: 'background',
    }
  },
  computed: {
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile
    },
    objectType() {
      if (!this.object) {
        return false
      }
      if (this.object.getAttr('isBackground')) {
        return 'Background'
      }
      return this.object.getClassName()
    },
  },
  methods: {
    addTextAndSelect() {
      this.activeTab = 'text'
      // Emit event back to parent
      this.$emit('add-text-and-select')
    },
    selectBackground() {
      this.activeTab = 'background'
      // Emit event back to parent
      this.$emit('select-background')
    },
    createTempImageObjectToOpenAttributeEditor() {
      this.activeTab = 'imageChange'
      // Emit event back to parent
      this.$emit('create-temp-image-object-to-open-attribute-editor')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';


.attribute-add-elements {
  .btn-add-object {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 10px;

    .text-caption {
      line-height: 1.2em;
    }

    .v-btn.v-btn--fab {
      background-color: #fff !important;
    }

    &.btn-add-object-background {
      .v-btn {
        color: #ED3254;

        &.active {
          border: 1px solid #ED3254 !important;
          box-shadow: 0 0 12px #ED3254;
        }
      }
    }

    &.btn-add-object-text {
      .v-btn {
        color: #5D8EFB;

        &.active {
          border: 1px solid #5D8EFB !important;
          box-shadow: 0 0 12px #5D8EFB;
        }
      }
    }

    &.btn-add-object-image {
      .v-btn {
        color: #FFC431;

        &.active {
          border: 1px solid #FFC431 !important;
          box-shadow: 0 0 12px #FFC431;
        }
      }
    }

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      display: block;
      margin-right: 0;
      margin-bottom: 10px;

      //.text-caption {
      //  background: rgba(240,240,240,1);
      //  border-radius: 10px;
      //  padding: 2px 7px;
      //}
    }

  }
}

</style>
