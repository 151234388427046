<template>
  <div>

    <component :is="componentType" v-bind="componentProperties" :value="dialog" style="max-height: calc(100% - 60px); transition: none;">

      <v-card flat>

        <v-card-text class="pa-1 pa-lg-4 pt-3">
          <div v-if="loading">
            <v-progress-circular
                :size="100"
                :width="8"
                indeterminate
                class="mb-5"
            ></v-progress-circular>
            <p>Bezig met laden.. <br >Moment geduld alstublieft</p>
          </div>

          <div v-if="!loading" style="width: 100%">

<!--            <div class="choose-material">-->
<!--              <h2 class="mt-3">Kies materiaal</h2>-->
<!--              <v-container>-->
<!--                <v-row :dense="!isDesktop">-->

<!--                  <v-col cols="6" sm="4" v-for="(material) in productAttributes.material" v-bind:key="material.id">-->
<!--                    <v-card-->
<!--                        outlined-->
<!--                        @click="selectMaterial(material)"-->
<!--                        class="select-option"-->
<!--                        v-bind:class="{active: material.id === selectedMaterialID}"-->
<!--                    >-->
<!--                      <v-card-text class="pa-2">-->
<!--                        <h3>-->
<!--                          {{ material.name }}-->
<!--                          <v-tooltip bottom max-width="400" v-if="material.field_description_long">-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <v-icon-->
<!--                                  color="primary"-->
<!--                                  dark-->
<!--                                  v-bind="attrs"-->
<!--                                  v-on="on"-->
<!--                              >-->
<!--                                mdi-information-outline-->
<!--                              </v-icon>-->
<!--                            </template>-->
<!--                            <span v-html="material.field_description_long.value"></span>-->
<!--                          </v-tooltip>-->
<!--                        </h3>-->
<!--                        <div v-if="material.field_description" class="mt-1" v-html="material.field_description.value"></div>-->
<!--                        <v-img v-if="material.imageURL" :src="material.imageURL"></v-img>-->
<!--                      </v-card-text>-->
<!--                    </v-card>-->
<!--                  </v-col>-->

<!--                </v-row>-->
<!--              </v-container>-->
<!--            </div>-->


            <div class="choose-size" v-if="selectedMaterialID">
              <h2 class="mt-3">Kies formaat</h2>
              <v-container>
                <v-row :dense="!isDesktop">

                  <v-col cols="4" sm="3" v-for="(productVariation) in sizeComputed" v-bind:key="productVariation.id">
                    <v-card
                        outlined
                        @click="selectSize(productVariation)"
                        class="select-option"
                        v-bind:class="{active: productVariation.sku === selectedSKU}"
                    >
                      <v-card-text>
                        <template v-if="productVariation.type === 'window_banner'">
                          <h3>{{ productVariation.size.name }}</h3>

                          <span class="strikethrough-diagonal mr-1" v-if="productVariation.price !== productVariation.calculated_price">
                       &euro;{{ productVariation.price|formatPrice }}
                     </span>
                          <p class="font-weight-bold">
                            &euro;{{ productVariation.calculated_price|formatPrice }}
                          </p>

                          <v-img v-if="productVariation.imageURL" :src="productVariation.imageURL"></v-img>
                        </template>
                        <template v-if="productVariation.type === 'window_banner_custom'">
                          <h3>Vrij formaat</h3>
                          <v-img v-if="productVariation.imageURL" :src="productVariation.imageURL"></v-img>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-col>

                </v-row>
              </v-container>
            </div>


            <v-footer
                color="primary"
                class="product-overview py-3 py-lg-6"
                app
                dark
                v-if="selectedSKU"
            >
              <h3 class="price d-inline-block mr-4">&euro;{{ selectedProductVariation.calculated_price|formatPrice }}</h3>
              <v-btn :x-large="isDesktop" :large="!isDesktop" rounded depressed color="success" @click="addToCart">
                Bestellen
              </v-btn>
            </v-footer>

          </div>
        </v-card-text>


      </v-card>

    </component>

  </div>
</template>

<script>
import {VSheet, VNavigationDrawer} from 'vuetify/lib'

import Numeral from "numeral";
import "numeral/locales/nl-nl";

// switch between locales
Numeral.locale('nl-nl');

export default {
  name: 'productEditor',
  components: {
    VSheet,
    VNavigationDrawer,
  },
  filters: {
    formatPrice(value) {
      return Numeral(value).format("€0.00"); // displaying other groupings/separators is possible, look at the docs
    }
  },
  props: {
    productID: {
      type: String,
      default: '',
    },
    selectedSKU: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      dialog: true,
      loading: true,
      selectedMaterialID: null,
      selectedSizeID: null,
      productVariations: [],
      productAttributes: {},
    }
  },
  computed: {
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile
    },
    componentType() {
      if (this.isDesktop) {
        return 'VNavigationDrawer'
      }
      else {
        return 'VSheet'
      }
    },
    componentProperties() {
      if (this.isDesktop) {
        return {
          app: true,
          width: 640,
          clipped: true,
        }
      }
      else {
        return {
          app: true,
          scrollable: true,
          fullscreen: true,
        }
      }
    },
    sizeComputed() {
      // Filter out product variations that are not the same material
      let productVariationsFilteredByMaterial = this.productVariations.filter(obj => {
        return obj.attributes.attribute_material === this.selectedMaterialID
      })

      // Loop over all product variations
      for (const productVariation of productVariationsFilteredByMaterial){
        // Add size data
        productVariation.size = this.getSize(productVariation.attributes.attribute_size)
      }

      return productVariationsFilteredByMaterial
    },
    selectedProductVariation() {
      return this.productVariations.find(x => x.sku === this.selectedSKU)
    }
  },
  watch: {
    selectedSKU: function() {
      this.setDefaultSelectedMaterialID()
    }
  },
  activated() {
    // Re activate dialog, needed for v-dialog to re-enable when re-activating the component
    this.dialog = true
  },
  async created() {
    // Load product variations from server
    const response = await fetch(process.env.VUE_APP_API_BASEURL+"api/product/"+this.productID+'/variations')
    // Show error when there is something wrong
    if (!response.ok) {
      this.$toast.error("Product attributen kon niet geladen worden. Foutcode: ["+response.status + "] "+response.statusText, {timeout: false});
      return
    }

    const data = await response.json();
    // Load product variations into data
    this.productVariations = data.productVariations
    this.productAttributes = data.productAttributes

    this.setDefaultSelectedMaterialID()

    // Set flag
    this.loading = false
  },
  methods: {
    setDefaultSelectedMaterialID() {
      // Get the selected product variation
      let selectedProductVariation = this.selectedProductVariation
      if (selectedProductVariation) {
        // Set the selected material
        this.selectedMaterialID = selectedProductVariation.attributes.attribute_material
      }
      else {
        // No material/sku has been selected yet. Pre-select them for the user.
        // Get the first material and select it.
        this.selectedMaterialID = this.productAttributes.material[0].id
        // Select the first size of the first material
        this.selectSize(this.sizeComputed[0])
      }
    },
    selectMaterial(item) {
      this.selectedMaterialID = item.id
      // Select the first size of the first material
      this.selectSize(this.sizeComputed[0])
    },
    selectSize(item) {
      // Emit selected SKU
      this.$emit('selected-is-sku', item)
    },
    getSize(sizeID) {
      return this.productAttributes.size.find(x => x.id === sizeID)
    },
    addToCart() {
      // Emit event
      this.$emit('add-to-cart')
    },
    openAttributeEditor() {
      this.dialog = false
      this.$emit('open-attribute-editor')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.select-option {
  &:hover {
    outline: 2px solid #d7d7d7;
  }
  &.active {
    outline: 2px solid blue;
  }
}
.strikethrough-diagonal {
  color: #a6a6a6;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 45%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    -webkit-transform: skewY(-10deg);
    -moz-transform: skewY(-10deg);
    transform: skewY(-10deg);
  }
}
</style>
