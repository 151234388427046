<template>
  <div>

    <v-app-bar app color="white" :height="isDesktop ? 60 : 40" flat clipped-left style="border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;">

      <template v-if="editorMode === 'attributeEditor'">

       <v-container fluid class="px-0">
         <v-row dense>
           <v-col cols="3" md="2" class="d-flex align-center justify-start">

            <!-- Show reset design button when there is no more steps back in the history -->
             <v-btn
                 color="warning"
                 depressed
                 :small="!isDesktop"
                 @click="showResetDesignDialog"
                 class="mr-1"
             >
               <v-icon small>mdi-refresh</v-icon>
             </v-btn>
             <!-- Show undo button -->
             <v-btn
                 color="primary"
                 depressed
                 :small="!isDesktop"
                 @click="undoHistory"
                 :disabled="historyIndex <= 0"
             >
               <v-icon small>mdi-undo</v-icon>
             </v-btn>

             <v-btn
                 color="primary"
                 depressed
                 :small="!isDesktop"
                 @click="redoHistory"
                 :disabled="historyIndex >= history.length - 1"
             >
               <v-icon small>mdi-redo</v-icon>
             </v-btn>

             <v-btn
                 color="#1fb2d2"
                 dark
                 @click="windowHistory.go(-1); return false;"
                 depressed
                 small
                 v-if="isDesktop"
                 class="ml-3"
             >
               <span>Alle ontwerpen</span>
             </v-btn>
           </v-col>
           <v-col class="d-flex align-center justify-center">
             <v-toolbar-title class="toolbar-title mt-1">

               <img
                   src="@/assets/logo.png"
                   alt=""
                   height="30"
                   width="153"
                   :class="isDesktop ? 'toolbar-title-logo mt-4' : 'toolbar-title-logo'"
               />

             </v-toolbar-title>
           </v-col>
           <v-col cols="2" class="d-flex justify-end">
             <v-btn
                 href="#"
                 color="success"
                 :small="!isDesktop"
                 :large="isDesktop"
                 rounded
                 depressed
                 @click="openProductEditor"
                 :class="isDesktop ? 'mt-2 ml-4' : 'mt-1 ml-4'"
             >
               <span>Verder</span>
               <v-icon small>mdi-arrow-right</v-icon>
             </v-btn>
           </v-col>
         </v-row>
       </v-container>

      </template>
      <template v-else-if="editorMode === 'productEditor'">

        <v-row>
          <v-col cols="2" class="d-flex justify-start">
            <v-btn
                color="success"
                :small="!isDesktop"
                rounded
                depressed
                @click="openAttributeEditor"
            >
              <v-icon small>mdi-arrow-left</v-icon>
              <span v-text="isDesktop ? 'Ontwerp aanpassen' : 'Ontwerp'"></span>
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-space-around">
            <v-toolbar-title class="toolbar-title">
              <img src="@/assets/logo.png" alt="" height="30" width="153" class="toolbar-title-logo" />
            </v-toolbar-title>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-btn
                color="primary"
                href="#"
                text
                outlined
                rounded
                :loading="designIsSaving"
                :disabled="designIsSaving"
                :small="!isDesktop"
                @click="saveDesignToMyDesigns"
            >
              <v-icon small>mdi-content-save</v-icon>
              <span>Opslaan</span>
            </v-btn>
          </v-col>
        </v-row>

      </template>

    </v-app-bar>

    <v-main class="main-wrapper" style="transition: none;">

      <!-- Load webfonts before creating the canvas -->
      <v-overlay
          :value="loading || designIsSaving"
          z-index="20"
      >
        <v-progress-circular
            :size="100"
            :width="8"
            indeterminate
            class="mb-5"
        ></v-progress-circular>
        <p>Bezig met laden.. <br >Moment geduld alstublieft</p>
      </v-overlay>


      <div v-if="editorMode === 'attributeEditor'">
        <attribute-editor
            ref="attributeEditor"
            :object="selectedObject"
            :designUUID="designUUID"
            :background-locked="backgroundLockedComputed"
            :workspace-one-object-editing="workSpaceOneTextObjectEditingComputed"
            :user-is-admin="userIsAdmin"
            @add-text-and-select="addTextAndSelect"
            @select-background="selectBackground"
            @load-and-add-new-image="loadAndAddNewImage"
            @load-and-update-existing-image="loadAndUpdateExistingImage"
            @create-temp-image-object-to-open-attribute-editor="createTempImageObjectToOpenAttributeEditor"
            @object-updated="objectUpdate"
            @object-destroy="objectDestroy"
            @object-move-up="objectMoveUp"
            @object-move-down="objectMoveDown"
            @background-updated="backgroundUpdate"
            @set-background-image="loadAndAddNewBackgroundImage"
            @delete-background-image="backgroundImageDeleteObject"
            @open-product-editor="openProductEditor"
        ></attribute-editor>
      </div>
      <div v-else-if="editorMode === 'productEditor'">
        <product-editor
            :productID="'16'"
            :selectedSKU="productVariationSKU"
            @selected-is-sku="selectedIsSKU"
            @add-to-cart="addDesignToCart"
            @open-attribute-editor="openAttributeEditor"
        ></product-editor>
        <div id="preview">
          <h2>Controleer uw ontwerp</h2>
          <img :src="canvasPreview" alt="">
        </div>
      </div>

      <!-- This always needs be available, on both the attributeEditor and productEditor -->
      <div id="stage-parent" v-show="editorMode === 'attributeEditor'">
        <div id="canvas"></div>
      </div>

      <div v-if="selectedObject && !selectedObject.getAttr('isBackground')" class="contextual-actions" v-bind:style="{ top: contextualActionsY + 'px', left: contextualActionsX + 'px' }">
        <v-btn
            x-small
            v-if="selectedObject.getAttr('src')"
            @click="openEditImageUploadDialog"
            class="icon-above-text"
            height="33"
        >
          <v-icon small>mdi-image-outline</v-icon>
          <span>Wissel</span>
        </v-btn>
        <image-cropper
            v-if="selectedObject.getAttr('src')"
            :designUUID="designUUID"
            :original-image="selectedObject.getAttr('src')"
            @image-cropped="imageCropped"
        ></image-cropper>
        <v-btn
            x-small
            @click="resetRotation(selectedObject)"
            v-if="selectedObject.rotation() !== 0"
            class="icon-above-text"
            height="33"
        >
          <v-icon small>mdi-rotate-left</v-icon>
          <span>Rechtzetten</span>
        </v-btn>
        <v-btn
            x-small
            @click="objectDestroy(selectedObject.getAttr('id'))"
            v-if="!workSpaceOneTextObjectEditingComputed"
            class="icon-above-text"
            height="33"
            >
          <v-icon small>mdi-trash-can</v-icon>
          <span>Verwijder</span>
        </v-btn>
      </div>

      <v-dialog
          v-model="dialogSavedDesign"
          max-width="650"
      >
        <v-card>
          <v-card-title class="text-h5">
            Uw ontwerp is opgeslagen
          </v-card-title>
          <v-card-text>
            <template v-if="userIsLoggedIn">
              <p>
                Ga naar <v-btn rounded small outlined depressed color="success" :href="ApiBaseURL+'/user/my-designs-saved'">Mijn opgeslagen ontwerpen</v-btn> of bewaar onderstaande link waarmee u later weer verder kunt gaan met uw ontwerp.
              </p>
              <v-divider class="mb-5"></v-divider>
            </template>
            <template v-else>
              <p>Bewaar deze link waarmee u later weer verder kunt gaan met uw ontwerp.</p>
            </template>
            <p class="mt-3">Klik op de link om hem te kopi&euml;ren:</p>
            <code v-clipboard:copy="savedDesignUrl"
                  v-clipboard:success="onCopySuccess"
                  v-clipboard:error="onCopyError"
                  style="cursor: pointer"
            >
              <v-icon class="mr-1" small>mdi-clipboard-multiple-outline</v-icon>
              <template v-pre></template>{{ savedDesignUrl }}<template v-pre></template>
            </code>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="dialogSavedDesign = false"
            >
              Sluiten
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="resetDesignDialog"
          max-width="650"
      >
        <v-card>
          <v-card-title class="text-h5">
            Wilt u het ontwerp resetten naar het originele design?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="resetDesignDialog = false"
            >
              Annuleren
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="resetDesign"
            >
              Terug naar origineel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-main>

  </div>
</template>

<script>
import Konva from "konva";
// import canvasEditor from "@/components/canvasEditor";
import attributeEditor from "@/components/attributeEditor";
import productEditor from "@/components/productEditor";
// import store from "@/plugins/store";
import { uuid } from 'vue-uuid';
import _ from 'lodash';
import ImageCropper from "@/components/imageCropper.vue";

export default {
  components: {
    ImageCropper,
    // canvasEditor,
    attributeEditor,
    productEditor,
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      productVariationSKU: '',
      productID: '',
      productHoles: false,
      placeholders: '',
      designUUID: '',
      productWidth: 800,
      productHeight: 400,
      backgroundLocked: false,
      user: {},
      userCSRFToken: '',
      workSpaceOneTextObjectEditing: false, // When 'true' the user can only edit one text/image that is pre-selected
      designIsSaving: false,
      loading: true,
      stage: null,
      layerCanvas: null,
      layerDesign: null,
      productGroupShadow: null,
      productGroup: null, // Wrapper group that holds the design and holes
      productGroupDesign: null, // Group that hold the actual design
      productGroupHoles: null, // Hold the product holes
      backgroundPlaceholders: null, // Hold the background placeholders
      backgroundPlaceholderText: null,
      backgroundPlaceholderBackground: null,
      backgroundPlaceholderImage: null,
      transformer: null,
      transformerImage: null,
      sourceCode: [],
      sourceCodeOriginal: [],
      selectedObject: null,
      backgroundObject: null, // Background color
      backgroundObjectImage: null, // Background image
      editorMode: 'attributeEditor', // Can be 'attributeEditor' for designing or 'productEditor' when selecting a product
      websiteURL: process.env.VUE_APP_API_BASEURL,
      canvasPreview: null,
      windowHistory: null,
      dialogSavedDesign: false,
      isDraggingTransformIcon: false,
      dragStartX: 0,
      dragStartY: 0,
      dragScale: {
        x: 1,
        y: 1,
      },
      contextualActionsX: 0,
      contextualActionsY: 0,
      resetDesignDialog: false,
      history: [],
      historyIndex: -1,
      historyMax: 50,
      historyLastAction: 'user-edit',
    }
  },
  computed: {
    userIsAdmin() {
      if (!this.user.roles) {
        return false
      }
      return this.user.roles.includes('site_admin')
    },
    userIsLoggedIn() {
      if (!_.isEmpty(this.user) && this.user.isLoggedIn) {
        return true;
      }
      return false;
    },
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile
    },
    stageWidth() {
      if (this.isDesktop) {
        return this.windowWidth - this.$vuetify.application.left
      }
      else {
        return this.windowWidth
      }
    },
    stageHeight() {
      if (this.isDesktop) {
        return this.windowHeight - this.$vuetify.application.bar - this.$vuetify.application.top
      }
      else {
        return this.windowHeight - this.$vuetify.application.bar - this.$vuetify.application.top - this.$vuetify.application.footer
      }
    },
    productMargin() {
      if (this.isDesktop) {
        return 100
      }
      return 50
    },
    objectIsSelected() {
      return !!this.selectedObject
    },
    backgroundLockedComputed() {
      // Enable all controls for all users.
      return false;
      // if (this.userIsAdmin) {
      //   return false
      // }
      // else {
      //   // Anonymous users, use the flag set by the sourceCode
      //   return this.backgroundLocked
      // }
    },
    workSpaceOneTextObjectEditingComputed() {
      // Admins can always edit all objects
      if (this.userIsAdmin) {
        return false
      }
      else {
        // Anonymous users, use the flag set by the sourceCode
        return this.workSpaceOneTextObjectEditing
      }
    },
    transformerButtons() {
      if (!this.transformer) {
        return
      }
      return {
        rotater: {
          path:'<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-rotate</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M0.9,0.5c0.1,0,0.3,0.1,0.3,0.3L1.1,2.9c1-1.4,2.6-2.4,4.5-2.4c2.9,0,5.3,2.4,5.3,5.3c0,2.9-2.4,5.3-5.3,5.3c-1.4,0-2.6-0.5-3.6-1.4c-0.1-0.1-0.1-0.3,0-0.4L2.3,9c0.1-0.1,0.3-0.1,0.4,0c0.7,0.7,1.7,1.1,2.8,1.1c2.3,0,4.2-1.9,4.2-4.2S7.8,1.7,5.5,1.7c-1.7,0-3.2,1-3.8,2.5l2.7-0.1c0.1,0,0.3,0.1,0.3,0.3v0.6c0,0.1-0.1,0.3-0.3,0.3H0.3C0.1,5.2,0,5.1,0,4.9V0.8c0-0.1,0.1-0.3,0.3-0.3H0.9z"/></svg>',
          shape: this.transformer.findOne('.rotater')
        },
        top_center: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-transform</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M.07,3.66v-3A.58.58,0,0,1,.65.07h3A.29.29,0,0,1,4,.36V.94a.29.29,0,0,1-.29.29H1.23V3.66A.29.29,0,0,1,.94,4H.36A.29.29,0,0,1,.07,3.66Zm7-3.3V.94a.29.29,0,0,0,.29.29H9.77V3.66a.29.29,0,0,0,.29.29h.58a.29.29,0,0,0,.29-.29v-3a.58.58,0,0,0-.58-.58h-3A.29.29,0,0,0,7.05.36Zm3.59,6.69h-.58a.29.29,0,0,0-.29.29V9.77H7.34a.29.29,0,0,0-.29.29v.58a.29.29,0,0,0,.29.29h3a.58.58,0,0,0,.58-.58v-3A.29.29,0,0,0,10.64,7.05ZM4,10.64v-.58a.29.29,0,0,0-.29-.29H1.23V7.34a.29.29,0,0,0-.29-.29H.36a.29.29,0,0,0-.29.29v3a.58.58,0,0,0,.58.58h3A.29.29,0,0,0,4,10.64Z"/></svg>',
          shape: this.transformer.findOne('.top-center')
        },
        top_left: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-transform</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M.07,3.66v-3A.58.58,0,0,1,.65.07h3A.29.29,0,0,1,4,.36V.94a.29.29,0,0,1-.29.29H1.23V3.66A.29.29,0,0,1,.94,4H.36A.29.29,0,0,1,.07,3.66Zm7-3.3V.94a.29.29,0,0,0,.29.29H9.77V3.66a.29.29,0,0,0,.29.29h.58a.29.29,0,0,0,.29-.29v-3a.58.58,0,0,0-.58-.58h-3A.29.29,0,0,0,7.05.36Zm3.59,6.69h-.58a.29.29,0,0,0-.29.29V9.77H7.34a.29.29,0,0,0-.29.29v.58a.29.29,0,0,0,.29.29h3a.58.58,0,0,0,.58-.58v-3A.29.29,0,0,0,10.64,7.05ZM4,10.64v-.58a.29.29,0,0,0-.29-.29H1.23V7.34a.29.29,0,0,0-.29-.29H.36a.29.29,0,0,0-.29.29v3a.58.58,0,0,0,.58.58h3A.29.29,0,0,0,4,10.64Z"/></svg>',
          shape: this.transformer.findOne('.top-left')
        },
        top_right: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-transform</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M.07,3.66v-3A.58.58,0,0,1,.65.07h3A.29.29,0,0,1,4,.36V.94a.29.29,0,0,1-.29.29H1.23V3.66A.29.29,0,0,1,.94,4H.36A.29.29,0,0,1,.07,3.66Zm7-3.3V.94a.29.29,0,0,0,.29.29H9.77V3.66a.29.29,0,0,0,.29.29h.58a.29.29,0,0,0,.29-.29v-3a.58.58,0,0,0-.58-.58h-3A.29.29,0,0,0,7.05.36Zm3.59,6.69h-.58a.29.29,0,0,0-.29.29V9.77H7.34a.29.29,0,0,0-.29.29v.58a.29.29,0,0,0,.29.29h3a.58.58,0,0,0,.58-.58v-3A.29.29,0,0,0,10.64,7.05ZM4,10.64v-.58a.29.29,0,0,0-.29-.29H1.23V7.34a.29.29,0,0,0-.29-.29H.36a.29.29,0,0,0-.29.29v3a.58.58,0,0,0,.58.58h3A.29.29,0,0,0,4,10.64Z"/></svg>',
          shape: this.transformer.findOne('.top-right')
        },
        bottom_right: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-transform</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M.07,3.66v-3A.58.58,0,0,1,.65.07h3A.29.29,0,0,1,4,.36V.94a.29.29,0,0,1-.29.29H1.23V3.66A.29.29,0,0,1,.94,4H.36A.29.29,0,0,1,.07,3.66Zm7-3.3V.94a.29.29,0,0,0,.29.29H9.77V3.66a.29.29,0,0,0,.29.29h.58a.29.29,0,0,0,.29-.29v-3a.58.58,0,0,0-.58-.58h-3A.29.29,0,0,0,7.05.36Zm3.59,6.69h-.58a.29.29,0,0,0-.29.29V9.77H7.34a.29.29,0,0,0-.29.29v.58a.29.29,0,0,0,.29.29h3a.58.58,0,0,0,.58-.58v-3A.29.29,0,0,0,10.64,7.05ZM4,10.64v-.58a.29.29,0,0,0-.29-.29H1.23V7.34a.29.29,0,0,0-.29-.29H.36a.29.29,0,0,0-.29.29v3a.58.58,0,0,0,.58.58h3A.29.29,0,0,0,4,10.64Z"/></svg>',
          shape: this.transformer.findOne('.bottom-right')
        },
        bottom_left: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-transform</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M.07,3.66v-3A.58.58,0,0,1,.65.07h3A.29.29,0,0,1,4,.36V.94a.29.29,0,0,1-.29.29H1.23V3.66A.29.29,0,0,1,.94,4H.36A.29.29,0,0,1,.07,3.66Zm7-3.3V.94a.29.29,0,0,0,.29.29H9.77V3.66a.29.29,0,0,0,.29.29h.58a.29.29,0,0,0,.29-.29v-3a.58.58,0,0,0-.58-.58h-3A.29.29,0,0,0,7.05.36Zm3.59,6.69h-.58a.29.29,0,0,0-.29.29V9.77H7.34a.29.29,0,0,0-.29.29v.58a.29.29,0,0,0,.29.29h3a.58.58,0,0,0,.58-.58v-3A.29.29,0,0,0,10.64,7.05ZM4,10.64v-.58a.29.29,0,0,0-.29-.29H1.23V7.34a.29.29,0,0,0-.29-.29H.36a.29.29,0,0,0-.29.29v3a.58.58,0,0,0,.58.58h3A.29.29,0,0,0,4,10.64Z"/></svg>',
          shape: this.transformer.findOne('.bottom-left')
        },
        /*
        edit: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-edit</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M10.6,7.5c-0.2,0.8-0.6,1.5-1.2,2c-0.1,0.1-0.2,0.1-0.3,0L8.2,9C7.8,9.3,7.4,9.6,6.9,9.8v1.1c0,0.1-0.1,0.2-0.2,0.3c-0.8,0.2-1.6,0.2-2.3,0c-0.1,0-0.2-0.1-0.2-0.3V9.8C3.6,9.6,3.2,9.3,2.8,9L1.9,9.5c-0.1,0.1-0.2,0-0.3,0c-0.5-0.6-0.9-1.3-1.2-2c0-0.1,0-0.2,0.1-0.3l0.9-0.5c-0.1-0.5-0.1-1,0-1.5L0.5,4.6C0.4,4.5,0.4,4.4,0.4,4.3c0.2-0.8,0.6-1.5,1.2-2c0.1-0.1,0.2-0.1,0.3,0l0.9,0.5C3.2,2.4,3.6,2.2,4.1,2V0.9c0-0.1,0.1-0.2,0.2-0.3c0.7-0.2,1.6-0.2,2.3,0c0.1,0,0.2,0.1,0.2,0.3V2c0.5,0.2,0.9,0.4,1.3,0.8l0.9-0.5c0.1-0.1,0.2,0,0.3,0c0.5,0.6,0.9,1.3,1.2,2c0,0.1,0,0.2-0.1,0.3L9.6,5.1c0.1,0.5,0.1,1,0,1.5l0.9,0.5C10.6,7.2,10.6,7.3,10.6,7.5z M7.2,5.9c0-0.9-0.8-1.7-1.7-1.7S3.8,4.9,3.8,5.9s0.8,1.7,1.7,1.7S7.2,6.8,7.2,5.9z"/></svg>',
          shape: this.transformer.findOne('.top-left')
        },
        */
        // bottom_center:{
        //   path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-delete</title><circle cx="8" cy="8" r="8" style="fill:#ffffff"/><path d="M10.24,1.08v.66a.39.39,0,0,1-.36.36H1.12a.39.39,0,0,1-.36-.36V1.08A.39.39,0,0,1,1.12.72H3.64L3.82.3A.52.52,0,0,1,4.24,0h2.4a.61.61,0,0,1,.48.3L7.3.72H9.82C10.06.78,10.24.9,10.24,1.08ZM1.42,2.82h8.1V9.91a1.05,1.05,0,0,1-1,1H2.44a1.05,1.05,0,0,1-1-1ZM3.1,9.19a.39.39,0,0,0,.36.36.39.39,0,0,0,.36-.36V4.44a.39.39,0,0,0-.36-.36.39.39,0,0,0-.36.36Zm2,0a.36.36,0,0,0,.72,0V4.44a.36.36,0,1,0-.72,0Zm2,0a.36.36,0,0,0,.72,0V4.44a.36.36,0,0,0-.72,0Z"/></svg>',
        //   shape: this.transformer.findOne('.bottom-center')
        // },
      }
    },
    transformerImageButtons() {
      if (!this.transformerImage) {
        return
      }
      return {
        rotater: {
          path:'<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-rotate</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M0.9,0.5c0.1,0,0.3,0.1,0.3,0.3L1.1,2.9c1-1.4,2.6-2.4,4.5-2.4c2.9,0,5.3,2.4,5.3,5.3c0,2.9-2.4,5.3-5.3,5.3c-1.4,0-2.6-0.5-3.6-1.4c-0.1-0.1-0.1-0.3,0-0.4L2.3,9c0.1-0.1,0.3-0.1,0.4,0c0.7,0.7,1.7,1.1,2.8,1.1c2.3,0,4.2-1.9,4.2-4.2S7.8,1.7,5.5,1.7c-1.7,0-3.2,1-3.8,2.5l2.7-0.1c0.1,0,0.3,0.1,0.3,0.3v0.6c0,0.1-0.1,0.3-0.3,0.3H0.3C0.1,5.2,0,5.1,0,4.9V0.8c0-0.1,0.1-0.3,0.3-0.3H0.9z"/></svg>',
          shape: this.transformerImage.findOne('.rotater')
        },
        top_center: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-transform</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M.07,3.66v-3A.58.58,0,0,1,.65.07h3A.29.29,0,0,1,4,.36V.94a.29.29,0,0,1-.29.29H1.23V3.66A.29.29,0,0,1,.94,4H.36A.29.29,0,0,1,.07,3.66Zm7-3.3V.94a.29.29,0,0,0,.29.29H9.77V3.66a.29.29,0,0,0,.29.29h.58a.29.29,0,0,0,.29-.29v-3a.58.58,0,0,0-.58-.58h-3A.29.29,0,0,0,7.05.36Zm3.59,6.69h-.58a.29.29,0,0,0-.29.29V9.77H7.34a.29.29,0,0,0-.29.29v.58a.29.29,0,0,0,.29.29h3a.58.58,0,0,0,.58-.58v-3A.29.29,0,0,0,10.64,7.05ZM4,10.64v-.58a.29.29,0,0,0-.29-.29H1.23V7.34a.29.29,0,0,0-.29-.29H.36a.29.29,0,0,0-.29.29v3a.58.58,0,0,0,.58.58h3A.29.29,0,0,0,4,10.64Z"/></svg>',
          shape: this.transformerImage.findOne('.top-center')
        },
        top_left: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-transform</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M.07,3.66v-3A.58.58,0,0,1,.65.07h3A.29.29,0,0,1,4,.36V.94a.29.29,0,0,1-.29.29H1.23V3.66A.29.29,0,0,1,.94,4H.36A.29.29,0,0,1,.07,3.66Zm7-3.3V.94a.29.29,0,0,0,.29.29H9.77V3.66a.29.29,0,0,0,.29.29h.58a.29.29,0,0,0,.29-.29v-3a.58.58,0,0,0-.58-.58h-3A.29.29,0,0,0,7.05.36Zm3.59,6.69h-.58a.29.29,0,0,0-.29.29V9.77H7.34a.29.29,0,0,0-.29.29v.58a.29.29,0,0,0,.29.29h3a.58.58,0,0,0,.58-.58v-3A.29.29,0,0,0,10.64,7.05ZM4,10.64v-.58a.29.29,0,0,0-.29-.29H1.23V7.34a.29.29,0,0,0-.29-.29H.36a.29.29,0,0,0-.29.29v3a.58.58,0,0,0,.58.58h3A.29.29,0,0,0,4,10.64Z"/></svg>',
          shape: this.transformerImage.findOne('.top-left')
        },
        top_right: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-transform</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M.07,3.66v-3A.58.58,0,0,1,.65.07h3A.29.29,0,0,1,4,.36V.94a.29.29,0,0,1-.29.29H1.23V3.66A.29.29,0,0,1,.94,4H.36A.29.29,0,0,1,.07,3.66Zm7-3.3V.94a.29.29,0,0,0,.29.29H9.77V3.66a.29.29,0,0,0,.29.29h.58a.29.29,0,0,0,.29-.29v-3a.58.58,0,0,0-.58-.58h-3A.29.29,0,0,0,7.05.36Zm3.59,6.69h-.58a.29.29,0,0,0-.29.29V9.77H7.34a.29.29,0,0,0-.29.29v.58a.29.29,0,0,0,.29.29h3a.58.58,0,0,0,.58-.58v-3A.29.29,0,0,0,10.64,7.05ZM4,10.64v-.58a.29.29,0,0,0-.29-.29H1.23V7.34a.29.29,0,0,0-.29-.29H.36a.29.29,0,0,0-.29.29v3a.58.58,0,0,0,.58.58h3A.29.29,0,0,0,4,10.64Z"/></svg>',
          shape: this.transformerImage.findOne('.top-right')
        },
        bottom_right: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-transform</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M.07,3.66v-3A.58.58,0,0,1,.65.07h3A.29.29,0,0,1,4,.36V.94a.29.29,0,0,1-.29.29H1.23V3.66A.29.29,0,0,1,.94,4H.36A.29.29,0,0,1,.07,3.66Zm7-3.3V.94a.29.29,0,0,0,.29.29H9.77V3.66a.29.29,0,0,0,.29.29h.58a.29.29,0,0,0,.29-.29v-3a.58.58,0,0,0-.58-.58h-3A.29.29,0,0,0,7.05.36Zm3.59,6.69h-.58a.29.29,0,0,0-.29.29V9.77H7.34a.29.29,0,0,0-.29.29v.58a.29.29,0,0,0,.29.29h3a.58.58,0,0,0,.58-.58v-3A.29.29,0,0,0,10.64,7.05ZM4,10.64v-.58a.29.29,0,0,0-.29-.29H1.23V7.34a.29.29,0,0,0-.29-.29H.36a.29.29,0,0,0-.29.29v3a.58.58,0,0,0,.58.58h3A.29.29,0,0,0,4,10.64Z"/></svg>',
          shape: this.transformerImage.findOne('.bottom-right')
        },
        bottom_left: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-transform</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M.07,3.66v-3A.58.58,0,0,1,.65.07h3A.29.29,0,0,1,4,.36V.94a.29.29,0,0,1-.29.29H1.23V3.66A.29.29,0,0,1,.94,4H.36A.29.29,0,0,1,.07,3.66Zm7-3.3V.94a.29.29,0,0,0,.29.29H9.77V3.66a.29.29,0,0,0,.29.29h.58a.29.29,0,0,0,.29-.29v-3a.58.58,0,0,0-.58-.58h-3A.29.29,0,0,0,7.05.36Zm3.59,6.69h-.58a.29.29,0,0,0-.29.29V9.77H7.34a.29.29,0,0,0-.29.29v.58a.29.29,0,0,0,.29.29h3a.58.58,0,0,0,.58-.58v-3A.29.29,0,0,0,10.64,7.05ZM4,10.64v-.58a.29.29,0,0,0-.29-.29H1.23V7.34a.29.29,0,0,0-.29-.29H.36a.29.29,0,0,0-.29.29v3a.58.58,0,0,0,.58.58h3A.29.29,0,0,0,4,10.64Z"/></svg>',
          shape: this.transformerImage.findOne('.bottom-left')
        },
        /*
        edit: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-edit</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M10.6,7.5c-0.2,0.8-0.6,1.5-1.2,2c-0.1,0.1-0.2,0.1-0.3,0L8.2,9C7.8,9.3,7.4,9.6,6.9,9.8v1.1c0,0.1-0.1,0.2-0.2,0.3c-0.8,0.2-1.6,0.2-2.3,0c-0.1,0-0.2-0.1-0.2-0.3V9.8C3.6,9.6,3.2,9.3,2.8,9L1.9,9.5c-0.1,0.1-0.2,0-0.3,0c-0.5-0.6-0.9-1.3-1.2-2c0-0.1,0-0.2,0.1-0.3l0.9-0.5c-0.1-0.5-0.1-1,0-1.5L0.5,4.6C0.4,4.5,0.4,4.4,0.4,4.3c0.2-0.8,0.6-1.5,1.2-2c0.1-0.1,0.2-0.1,0.3,0l0.9,0.5C3.2,2.4,3.6,2.2,4.1,2V0.9c0-0.1,0.1-0.2,0.2-0.3c0.7-0.2,1.6-0.2,2.3,0c0.1,0,0.2,0.1,0.2,0.3V2c0.5,0.2,0.9,0.4,1.3,0.8l0.9-0.5c0.1-0.1,0.2,0,0.3,0c0.5,0.6,0.9,1.3,1.2,2c0,0.1,0,0.2-0.1,0.3L9.6,5.1c0.1,0.5,0.1,1,0,1.5l0.9,0.5C10.6,7.2,10.6,7.3,10.6,7.5z M7.2,5.9c0-0.9-0.8-1.7-1.7-1.7S3.8,4.9,3.8,5.9s0.8,1.7,1.7,1.7S7.2,6.8,7.2,5.9z"/></svg>',
          shape: this.transformer.findOne('.top-left')
        },
        */
        // bottom_center:{
        //   path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-delete</title><circle cx="8" cy="8" r="8" style="fill:#ffffff"/><path d="M10.24,1.08v.66a.39.39,0,0,1-.36.36H1.12a.39.39,0,0,1-.36-.36V1.08A.39.39,0,0,1,1.12.72H3.64L3.82.3A.52.52,0,0,1,4.24,0h2.4a.61.61,0,0,1,.48.3L7.3.72H9.82C10.06.78,10.24.9,10.24,1.08ZM1.42,2.82h8.1V9.91a1.05,1.05,0,0,1-1,1H2.44a1.05,1.05,0,0,1-1-1ZM3.1,9.19a.39.39,0,0,0,.36.36.39.39,0,0,0,.36-.36V4.44a.39.39,0,0,0-.36-.36.39.39,0,0,0-.36.36Zm2,0a.36.36,0,0,0,.72,0V4.44a.36.36,0,1,0-.72,0Zm2,0a.36.36,0,0,0,.72,0V4.44a.36.36,0,0,0-.72,0Z"/></svg>',
        //   shape: this.transformerImage.findOne('.bottom-center')
        // },
      }
    },
    savedDesignUrl() {
      return window.location.href;
    },
    ApiBaseURL() {
      return process.env.VUE_APP_API_BASEURL;
    }
  },
  watch: {
    productVariationSKU: function(newSKU) {
      // Update URL parameter 'sku'
      let urlParams = new URLSearchParams(window.location.search);
      urlParams.set('sku', newSKU)
      history.replaceState(null, null, "?"+urlParams.toString());
    }
  },
  async mounted() {
    let $vm = this;

    this.windowHistory = window.history

    // Make window height and widht reactive
    window.onresize = () => {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    }

    // Check if user is logged
    // Set 'credentials: "include"' to make sure the cookies are send with the request.
    const responseUser = await fetch(process.env.VUE_APP_API_BASEURL+'api/user', {
      credentials: "include"
    })
    // Set user data
    this.user = await responseUser.json();

    // Fetch session token
    const responseUserSessionToken = await fetch(process.env.VUE_APP_API_BASEURL+'session/token', {
      credentials: "include"
    })
    // Set CSRF token
    responseUserSessionToken.text().then(function (text) {
      $vm.userCSRFToken = text
    });

    // Get URL parameters
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('designUUID')) {
      // Set design UUID
      this.designUUID = urlParams.get('designUUID')
    }
    if (urlParams.has('productID')) {
      // Set product ID
      this.productID = urlParams.get('productID')
    }
    if (urlParams.has('sku')) {
      // Set product variation SKU
      this.productVariationSKU = urlParams.get('sku')
    }

    // Create the canvas
    this.createCanvas()

    // Wait for fonts to be ready. We need to wait, otherwise the text shapes are not rendered correctly.
    await this.fontsReady().then(async function () {
      if ($vm.designUUID) {
        // Load existing design
        await $vm.loadExistingDesign($vm.designUUID)
        $vm.loading = false
        // Add to history
        $vm.updateHistory();
        // Resize canvas when resizing window
        $vm.fitProductGroupWithinCanvasDebounced();
      }
      else {
        // Create new design
        $vm.createNewDesign()
        $vm.loading = false
        // Add to history
        $vm.updateHistory();
        // Resize canvas when resizing window
        $vm.fitProductGroupWithinCanvasDebounced();
      }
    });

    // Bind pointer (mouse + touch) move
    this.$el.addEventListener('pointermove', this.eventDragMove)
    // Stop dragging dragging the transformer "move" icon has ended
    this.$el.addEventListener('pointerup', this.eventDragEnd)

    // adapt the stage on any window resize
    window.addEventListener('resize', this.fitProductGroupWithinCanvasDebounced);

  },
  methods: {
    eventDragMove(event) {
        if (!this.isDraggingTransformIcon) {
          return
        }
        // Hide contextual actions
        this.hideContextualAction();
        // Calculate the difference between drag start and the current drag
        let positionX = (event.clientX - this.dragStartX) / this.dragScale.x
        let positionY = (event.clientY - this.dragStartY) / this.dragScale.y
        // Translate object
        this.selectedObject.move({
          x: positionX,
          y: positionY,
        })
        // Reset dragStart positions
        this.dragStartX = event.clientX
        this.dragStartY = event.clientY
    },
    eventDragEnd() {
      this.setContextualActionsPosition();
      this.isDraggingTransformIcon = false
    },
    setContextualActionsPosition() {
      let selectedObject = this.selectedObject;
      // Set contextual actions position
      if (selectedObject) {
        let scaleX = selectedObject.getAbsoluteScale().x;
        let scaleY = selectedObject.getAbsoluteScale().y;

        let x = selectedObject.getAbsolutePosition().x;
        let y = selectedObject.getAbsolutePosition().y;

        this.contextualActionsX = x + selectedObject.width() * scaleX / 2;
        this.contextualActionsY = y + selectedObject.height() * scaleY + 15;
      }
    },
    hideContextualAction() {
      this.contextualActionsX = -99999;
      this.contextualActionsY = -99999;
    },
    async generatePreview() {
      let image_export = await this.exportCanvasToImage(true)
      // Replace preview with the new exported canvas
      this.canvasPreview = image_export
    },
    async openProductEditor() {
      // Save source code so we can rebuild the canvas when switching back to the attributeEditor
      this.sourceCode = this.saveSourceCode()
      // Generate a preview of the canvas
      await this.generatePreview()
      // Open the product editor
      this.editorMode = 'productEditor';
    },
    openAttributeEditor() {
      let $vm = this;
      this.editorMode = 'attributeEditor';
      this.$nextTick(async function(){
        // Recreate the canvas
        $vm.createCanvas()
        // Restore the canvas from the sourceCode
        await $vm.loadSourceCode($vm.sourceCode)
        // Pre-select object
        this.preSelectObject()
        // Resize canvas when resizing window
        $vm.fitProductGroupWithinCanvasDebounced();
      })
    },
    selectedIsSKU(productVariationItem) {
      // Set selected SKU
      this.productVariationSKU = productVariationItem.sku
      // Set product holes amount/type
      this.productHoles = '4'
      // Check if ratio of new product is different from the previous one
      let ratioOld = this.productWidth / this.productHeight
      let ratioNew = productVariationItem.size.width / productVariationItem.size.height
      if (ratioOld !== ratioNew) {
        // Resize the canvas when the newly selected product has different ratio then the old one
        this.resizeCanvas()
      }
    },
    async resizeCanvas() {
      // Set new product size
      this.productWidth = 800
      this.productHeight = 800
      // Recreate the canvas
      this.createCanvas()
      // Load the previous design into the newly created canvas
      await this.loadSourceCode(this.sourceCode)
      // Update the size of the product background
      this.backgroundFitWithinCanvas()
      // Make sure the new canvas fits within container
      this.fitProductGroupWithinCanvas();
      // Save/update sourceCode. Needed because we updated it.
      this.sourceCode = this.saveSourceCode()
      // Generate a new preview
      await this.generatePreview()
    },
    addText(user_properties = {}) {
      let $vm = this;

      let default_properties = {
        id: uuid.v4(),
        x: this.productGroupDesign.width() / 2 - 100,
        y: this.productGroupDesign.height() / 2 - 50,
        text: "Uw tekst",
        fontSize: 64,
        fontFamily: 'Arial',
        fill: '#000000',
        scaleX: 1,
        scaleY: 1,
        // width: 300,
        padding: 2,
        align: 'center',
        myAlign: 'center',
        draggable: true,
        transformable: true,
      }
      // Merge user properties with the default properties.
      let properties = {
        ...default_properties,
        ...user_properties
      }

      let text = new Konva.Text(properties);

      // Set align via myAlign when set, we need this because KonvaJS doesn't export the 'align' property
      if (properties.myAlign) {
        // Set align, we need to do this after creating the object because it does not work when creating it
        text.align(properties.myAlign)
      }

      // add the shapes to the layer
      this.productGroupDesign.add(text);

      // Hide contextual actions when transforming and dragging
      text.on('transformstart dragstart', function () {
        $vm.hideContextualAction();
      });

      return text
    },
    addTextAndSelect() {
      // Add text
      let text = this.addText()
      // Select the newly created object
      this.selectObject(text)
      // Delete placeholders
      this.removeBackgroundPlaceholderText()
    },
    createTempImageObjectToOpenAttributeEditor() {
      // Create temp image to open the image selector
      let image = this.addImage(null, {
        x: -9999,
        y: -9999,
        width: this.productGroupDesign.width(),
        height: this.productGroupDesign.height(),
        isNewImage: true
      });
      // Open the upload dialog
      this.openEditImageUploadDialog();
      // Select the newly created object
      this.selectObject(image)
    },
    addImage(imageObj, user_properties, zIndex = null) {
      let $vm = this
      // Get cropWidth and cropHeight
      let cropWidth = user_properties.cropWidth ? user_properties.cropWidth : null
      let cropHeight = user_properties.cropHeight ? user_properties.cropHeight : null

      let default_properties = {
        id: uuid.v4(),
        src: '',
        image: imageObj,
        x: 0,
        y: 0,
        width: 200,
        height: 200,
        draggable: true,
        transformable: true,
      }
      // Merge user properties with the default properties.
      let properties = {
        ...default_properties,
        ...user_properties
      }

      // Lock the image for non-admins
      if (properties.isLocked && !this.userIsAdmin) {
        properties.draggable = false
        properties.transformable = false
      }

      // Use the crop width and height instead of the default width and height when the image is cropped
      if (cropWidth) {
        properties.width = cropWidth
      }
      if (cropHeight) {
        properties.height = cropHeight
      }

      // Create new Konva image
      let image = new Konva.Image(properties);

      // Add image to layer
      this.productGroupDesign.add(image);

      // Find all objects that are below the locked image.
      if (properties.isLocked) {

        let latestClick;
        let previousTargetId;

        image.on("click touchend", function() {

          // Check if it's a double click/tap or single click/tap
          let clickType = 'single';
          let now = new Date().getTime();
          let timesince = now - latestClick;
          if ((timesince < 600) && (timesince > 0) && image.getAttr('id') === previousTargetId) {
            // Double click/tap
            clickType = 'double';
          }
          latestClick = new Date().getTime();
          previousTargetId = image.getAttr('id');

          // Get clicked mouse position
          let position = $vm.stage.getPointerPosition();
          // Find all objects on this position
          let targets = $vm.stage.getAllIntersections(position);
          // Loop over all target that we found
          for (let target of targets) {
            // Find the parent of the object
            let parent = target.getParent()
            if (parent.name() !== 'productGroupDesign') {
              continue; // Skip objects that are not within this.productGroupDesign
            }

            // Select the first image we find that has a lower zIndex than locked image we clicked on
            // Never select backgrounds that are behind the locked image
            if (target.getClassName() === 'Image' && !target.getAttr('isBackground')) {
              if (clickType === 'double') {
                // Open the upload dialog
                $vm.openEditImageUploadDialog();
              }
              $vm.selectObject(target)
              return
            }

            // @TODO: Want to select multiple objects behind transparant image? This is some code as a starting point..
            // @TODO: We have to find the zIndex of all objects behind the image and then select a object that is 1 less zIndex than the currently selected object.
            // // Select the object that is one below the current selected zIndex
            // let currentlySelectedZindex = 0
            // if ($vm.selectedObject !== null) {
            //   currentlySelectedZindex = $vm.selectedObject.zIndex()
            // }
            // Select an object that is one below the currently selected Zindex
            // console.log('currentlySelectedZindex = ' +currentlySelectedZindex)

          }

        });


        // Support move/dragging on locked images
        let isDragging = false;
        let dragStartX = 0;
        let dragStartY = 0;

        image.on('mousedown', function () {
          isDragging = true;
          dragStartX = $vm.stage.getPointerPosition().x;
          dragStartY = $vm.stage.getPointerPosition().y;
        });

        $vm.stage.on('mousemove', function () {
          if (!isDragging) {
            return;
          }

          let newDragStartX = $vm.stage.getPointerPosition().x;
          let newDragStartY = $vm.stage.getPointerPosition().y;

          // Get the difference between the drag start and the current drag
          let diffX = newDragStartX - dragStartX;
          let diffY = newDragStartY - dragStartY;

          // Set the new drag start
          dragStartX = newDragStartX;
          dragStartY = newDragStartY;

          // Update the position of the selected object
          $vm.selectedObject.move({
            x: diffX,
            y: diffY,
          });
        });

        // Stop dragging
        $vm.stage.on('mouseup', function () {
          // Reset dragStart positions
          isDragging = false;
          dragStartX = 0;
          dragStartY = 0;
        });

      }

      // Hide contextual actions when transforming and dragging
      image.on('transformstart dragstart', function () {
        $vm.hideContextualAction();
      });

      if (cropWidth || cropHeight) {
        // Calculate scalingRatio
        const scalingRatio = properties.widthOriginal / image.width()
        // Set the scale
        image.scaleX(scalingRatio)
        image.scaleY(scalingRatio)

        // reset scale on transform
        this.resetAfterCrop(image)
        // Apply the crop
        this.applyCrop(image);
      }

      // Set zIndex
      if (zIndex) {
        image.setZIndex(zIndex)
      }

      return image
    },
    loadAndAddNewImage(image) {
      // Delete placeholders
      this.removeBackgroundPlaceholderImage()
      // Make Vue 'this' accessible inside other functions
      let $vm = this;
      let imageObj = new Image();
      imageObj.onload = function () {
        // Get image dimensions
        let imageWidth = imageObj.width
        let imageHeight = imageObj.height

        let maxWidth = $vm.productWidth
        let maxHeight = $vm.productHeight

        let iwScaled = imageWidth;
        let ihScaled = imageHeight;

        // Scale image down to make sure it fits within the product group
        if (imageWidth > maxWidth || imageHeight > maxHeight) {
          let scale = Math.min((maxWidth/imageWidth),(maxHeight/imageHeight));
          iwScaled = imageWidth*scale;
          ihScaled = imageHeight*scale;
        }

        // Set image properties
        let properties = {
          x: $vm.productGroupDesign.width() / 2 - iwScaled / 2,
          y: $vm.productGroupDesign.height() / 2 - ihScaled / 2,
          width: iwScaled,
          height: ihScaled,
          myWidth: iwScaled, // Save here because KonvaJS removes 'width' and 'height' property when exporting source code
          myHeight: ihScaled, // Save here because KonvaJS removes 'width' and 'height' property when exporting source code
          fid: image.fid,
          src: image.url,
        }
        // Add Konva image when image is fully loaded
        let imageKonvaObject = $vm.addImage(this, properties);
        // Select the newly created object
        $vm.selectObject(imageKonvaObject)
      };
      imageObj.crossOrigin = 'Anonymous';
      imageObj.src = image.url;
    },
    loadAndAddExistingImage(element, zIndex, callback = null) {
      // Make Vue 'this' accessible inside other functions
      let $vm = this;
      let imageObj = new Image();
      imageObj.onload = function () {
        // Add Konva image when image is fully loaded
        $vm.addImage(this, element.attrs, zIndex);
        // Execute callback
        if (callback) {
          callback()
        }
      };
      imageObj.onerror = function () {
        $vm.$toast.warning("Afbeelding kon niet geladen worden en is niet toegevoegd. Het ontwerp is daarom niet helemaal compleet.", {timeout: false});
        if (callback) {
          callback()
        }
      };
      imageObj.crossOrigin = 'Anonymous';
      imageObj.src = element.attrs.src;
    },
    loadAndUpdateExistingImage({image, id}) {
      // Delete placeholders
      this.removeBackgroundPlaceholderImage()
      let $vm = this
      // Find the object on the canvas
      let objectKonva = this.stage.findOne('#'+id);
      // Get the previous image size
      let oldWidth = objectKonva.width()
      let oldHeight = objectKonva.height()
      // Get the previous X and Y
      let oldX = objectKonva.x()
      let oldY = objectKonva.y()

      // If its flag as a new image, it needs to be positioned from outside the canvas where it's created temporarily.
      if (objectKonva.getAttr('isNewImage')) {
        // Re-position
        oldX = 0
        oldY = 0;
        // Remove flag
        objectKonva.setAttr('isNewImage', null)
      }

      // Wait for image to fully load
      let imageObj = new Image();
      imageObj.onload = function () {
        // Get image dimensions
        let imageWidth = imageObj.width
        let imageHeight = imageObj.height

        let iwScaled = imageWidth;
        let ihScaled = imageHeight;
        // Scale image down to make sure it fits within the boundaries of the old image
        if (imageWidth > oldWidth || imageHeight > oldHeight) {
          let scale = Math.min((oldWidth/imageWidth),(oldHeight/imageHeight));
          iwScaled = imageWidth*scale;
          ihScaled = imageHeight*scale;
        }
        // Change the 'src' of the image
        objectKonva.image(imageObj)
        // Set 'fid' and 'src', needed for the source code
        objectKonva.setAttr('fid', image.fid)
        objectKonva.setAttr('src', image.url)

        // Set width and height
        objectKonva.width(iwScaled)
        objectKonva.height(ihScaled)
        // Set width and height in the properties
        objectKonva.setAttr('myWidth', iwScaled) // Save here because KonvaJS removes 'width' and 'height' property when exporting source code
        objectKonva.setAttr('myHeight', ihScaled) // Save here because KonvaJS removes 'width' and 'height' property when exporting source code

        // Center image within old boundaries
        // Calculate difference between old and new width/height
        let newWidth = iwScaled
        let newHeight = ihScaled
        let differenceWidth = (oldWidth - newWidth) * objectKonva.getAbsoluteScale().x
        let differenceHeight = (oldHeight - newHeight) * objectKonva.getAbsoluteScale().y

        // Set new position
        objectKonva.x(oldX + differenceWidth / 2)
        objectKonva.y(oldY + differenceHeight / 2)

        // Apply crop
        $vm.applyCrop(objectKonva)

        // Update transformers
        $vm.updateTransformer($vm.transformer)
        $vm.updateTransformerImage($vm.transformerImage)

        // Update contextual filter position
        $vm.setContextualActionsPosition();
      }
      imageObj.crossOrigin = 'Anonymous';
      imageObj.src = image.url;
    },
    objectUpdate(attributes) {

      // Find the object on the canvas
      let objectKonva = this.stage.findOne('#'+attributes.id);

      // Change X and Y position
      objectKonva.position({
        x: (attributes.x),
        y: (attributes.y),
      })
      // Set rotation
      objectKonva.rotation(attributes.rotation)

      // Apply stroke properties
      if (attributes.type === 'Text' || attributes.type === 'Image') {
        objectKonva.stroke(attributes.stroke)
        objectKonva.strokeWidth(attributes.strokeWidth)
      }

      if (attributes.type === 'Image') {
        objectKonva.setAttr('isLocked', attributes.isLocked)
        objectKonva.setAttr('clipPosition', attributes.clipPosition)
      }

      // Apply text related properties
      if (attributes.type === 'Text') {
        objectKonva.text(attributes.text);
        objectKonva.fontSize(attributes.fontSize);
        objectKonva.scaleX(attributes.scaleX);
        objectKonva.scaleY(attributes.scaleY);
        objectKonva.fontFamily(attributes.fontFamily);
        objectKonva.align(attributes.textAlign)
        objectKonva.setAttr('myAlign', attributes.textAlign) // Save here because KonvaJS removes 'align' property when exporting source code
        objectKonva.fill(attributes.fill)
        objectKonva.fontStyle(attributes.textStyle)
        objectKonva.textDecoration(attributes.textDecoration)

        // Deselect item and reselect it.
        // We need this because changing the font family does not update the transformer box.
        let selectedObject = this.selectedObject
        if (selectedObject) {
          this.deselectObject()
          this.selectObject(selectedObject)
        }
      }

      // Update transformers
      this.updateTransformer(this.transformer)
      this.updateTransformerImage(this.transformerImage)

      // Save new version to the history
      this.updateHistory();

    },
    backgroundUpdate(attributes) {
      // Change fill
      this.backgroundObject.fill(attributes.fill)
      // Delete placeholders
      this.removeBackgroundPlaceholderBackground()
    },
    loadAndAddNewBackgroundImage(image) {
      // Delete placeholders
      this.removeBackgroundPlaceholderBackground()
      // Remove the old background image
      this.backgroundImageDeleteObject()
      // Make Vue 'this' accessible inside other functions
      let $vm = this;
      let imageObj = new Image();
      imageObj.onload = function () {
        // Get image dimensions
        let imageWidth = imageObj.width
        let imageHeight = imageObj.height
        let maxWidth = $vm.productWidth
        let maxHeight = $vm.productHeight

        // get the scale
        let scale = Math.max(maxWidth / imageWidth, maxHeight / imageHeight);
        // get the top left position of the image
        let x = (maxWidth / 2) - (imageWidth / 2) * scale;
        let y = (maxHeight / 2) - (imageHeight / 2) * scale;
        // Get width and height scaled to fill
        let iwScaled = imageWidth * scale;
        let ihScaled = imageHeight * scale;

        // Set image properties
        let properties = {
          x: x,
          y: y,
          width: iwScaled,
          height: ihScaled,
          myWidth: iwScaled, // Save here because KonvaJS removes 'width' and 'height' property when exporting source code
          myHeight: ihScaled, // Save here because KonvaJS removes 'width' and 'height' property when exporting source code
          fid: image.fid,
          src: image.url,
          draggable: true,
          isBackground: true,
          transformable: false,
          isBackgroundImage: true,
        }
        // Add Konva image when image is fully loaded
        $vm.createBackgroundImageObject(this, properties);
        // Check if the new background size fits perfectly on the canvas
        let backgroundPerfectFit = $vm.backgroundObjectImage.x() === 0 && $vm.backgroundObjectImage.y() === 0
        if (!backgroundPerfectFit) {
          // Show help text
          $vm.$toast.success("U kunt de achtergrond verplaatsen door middel van klik+sleep op de achtergrond afbeelding.", {timeout: 7000});
        }
        // Select the newly created object
        // $vm.selectObject(imageKonvaObject)
      };
      imageObj.crossOrigin = 'Anonymous';
      imageObj.src = image.url;
    },
    loadAndAddExistingBackgroundImage(element, zIndex, callback = null) {
      // Make Vue 'this' accessible inside other functions
      let $vm = this;
      let imageObj = new Image();
      imageObj.onload = function () {
        // Add Konva image when image is fully loaded
        $vm.createBackgroundImageObject(this, element.attrs, zIndex);
        if (callback) {
          callback()
        }
      };
      imageObj.onerror = function () {
        $vm.$toast.warning("Afbeelding kon niet geladen worden en is niet toegevoegd. Het ontwerp is daarom niet helemaal compleet.", {timeout: false});
        if (callback) {
          callback()
        }
      };
      imageObj.crossOrigin = 'Anonymous';
      imageObj.src = element.attrs.src;
    },
    createBackgroundImageObject(image, properties) {
      let $vm = this;
      // Add Konva image when image is fully loaded
      let imageKonvaObject = $vm.addImage(image, properties);
      // Set drag bounds for background images
      imageKonvaObject.on('dragmove', () => {
        // Lock Y when height is bigger then width
        if (properties.x < 0) {
          imageKonvaObject.y(0);
        }
        // Lock X when width is bigger then height
        else if (properties.y < 0) {
          imageKonvaObject.x(0);
        }
        // Lock both X and Y when the background fits exactly
        else if (properties.x === 0 && properties.y === 0) {
          imageKonvaObject.x(0);
          imageKonvaObject.y(0);
        }
      });
      // Create background object
      $vm.backgroundObjectImage = imageKonvaObject
      // Make sure the image is 1 above the background color
      $vm.backgroundObjectImage.setZIndex(1)
      // add the shape to the layer
      $vm.productGroupDesign.add($vm.backgroundObjectImage);
      // Select background to make sure the 'background' attribute editor remains open
      $vm.selectBackground()
      // Clicks on background image deselect shapes
      $vm.backgroundObjectImage.on('click tap', function () {
        // Deselect object
        $vm.deselectObjectByUser()
        // if ($vm.isDesktop) {
          // Select background to make sure the 'background' attribute editor remains open
          $vm.selectBackground()
        // }
      });
    },
    backgroundImageDeleteObject() {
      // Remove the background image
      if (this.backgroundObjectImage !== null) {
        // Destroy konva object
        this.objectDestroy(this.backgroundObjectImage.id())
        // Set reference to null
        this.backgroundObjectImage = null
        // Select background to make sure the 'background' attribute editor remains open
        this.selectBackground()
      }
    },
    backgroundFitWithinCanvas() {
      this.backgroundObject.width(this.productWidth)
      this.backgroundObject.height(this.productHeight)
    },
    objectDestroy(id) {
      // Find the object on the canvas
      let objectKonva = this.stage.findOne('#'+id);
      // Deselect object
      this.deselectObject()
      if (objectKonva) {
        // Remove and destroy the object
        objectKonva.destroy()
      }
      // Select background to make sure the 'background' attribute editor remains open
      this.selectBackground()
    },
    objectMoveUp(id) {
      // Find the object on the canvas
      let objectKonva = this.stage.findOne('#'+id);
      // Move object up
      objectKonva.moveUp()
    },
    objectMoveDown(id) {
      // Find the object on the canvas
      let objectKonva = this.stage.findOne('#'+id);
      // Get current zIndex
      let objectZindex = objectKonva.zIndex()
      // Make sure we don't place the object behind the background image if it exists
      if (this.backgroundObjectImage && objectZindex > 2) {
        // Move object down
        objectKonva.moveDown()
      }
      // Make sure we don't place the object behind the background color
      else if (!this.backgroundObjectImage && objectZindex > 1) {
        // Move object down
        objectKonva.moveDown()
      }
    },
    selectObject(object) {
      let $vm = this

      // Disable selecting locked images when not admin
      if (object.getAttr('isLocked') && !this.userIsAdmin) {
        return;
      }

      // Make sure attributeEditor is active
      if (this.editorMode !== 'attributeEditor') {
        return
      }
      if (this.workSpaceOneTextObjectEditingComputed) {
        let enabledAnchors = ['top-left', 'top-center', 'top-right', 'bottom-right', 'bottom-left']
        this.transformer.enabledAnchors(enabledAnchors)
        this.transformerImage.enabledAnchors(enabledAnchors)
      }
      else {
        // let enabledAnchors = ['bottom-center', 'top-left', 'top-center', 'top-right', 'bottom-right', 'bottom-left']
        let enabledAnchors = ['top-left', 'top-center', 'top-right', 'bottom-right', 'bottom-left']
        this.transformer.enabledAnchors(enabledAnchors)
        this.transformerImage.enabledAnchors(enabledAnchors)
      }

      // Update transformer icons when the transformer is being used
      object.on('transform', function(){
        if (object.getClassName() === 'Image') {
          // reset scale on transform
          $vm.resetAfterCrop(object)
          // Apply the crop
          $vm.applyCrop(object)
          // Update transformer
          $vm.updateTransformerImage($vm.transformerImage)
        }
        else {
          $vm.updateTransformer($vm.transformer)
        }

      });
      object.on('mouseenter', function(){
        $vm.stage.container().style.cursor = 'move';
      });
      object.on('mouseleave', function(){
        $vm.stage.container().style.cursor = 'default';
      });

      // Choose transformer
      if (object.getClassName() === 'Image') {
        // Deselect objects in the default transformer
        this.transformer.nodes([])
        // Use the image transformer
        this.transformerImage.nodes([object]);
        this.selectedObject = object
      }
      else {
        // Deselect objects in the image transformer
        this.transformerImage.nodes([])
        // Use default transformer
        this.transformer.nodes([object]);
        this.selectedObject = object
      }

      // Set contextual actions position
      this.setContextualActionsPosition();

      // Update transformer style
      if (object.getClassName() === 'Image') {
        this.updateTransformerImage(this.transformerImage)
      }
      else {
        this.updateTransformer(this.transformer)
      }

    },
    resetAfterCrop(object) {
      // reset scale on transform
      object.setAttrs({
        scaleX: 1,
        scaleY: 1,
        width: object.width() * object.scaleX(),
        height: object.height() * object.scaleY(),
      });
    },
    applyCrop(object) {
      const crop = this.getCrop(
          object.image(),
          { width: object.width(), height: object.height() },
          object.getAttr('clipPosition'),
      );
      object.setAttrs(crop);
      object.setAttr('lastCropUsed', crop);
    },
    getCrop(image, size, clipPosition = 'center-middle') {
      const width = size.width;
      const height = size.height;
      const aspectRatio = width / height;

      let newWidth;
      let newHeight;

      const imageRatio = image.width / image.height;

      if (aspectRatio >= imageRatio) {
        newWidth = image.width;
        newHeight = image.width / aspectRatio;
      } else {
        newWidth = image.height * aspectRatio;
        newHeight = image.height;
      }

      let x = (image.width - newWidth) / 2;
      let y = (image.height - newHeight) / 2;

      if (clipPosition === 'left-top') {
        x = 0;
        y = 0;
      } else if (clipPosition === 'left-middle') {
        x = 0;
        y = (image.height - newHeight) / 2;
      } else if (clipPosition === 'left-bottom') {
        x = 0;
        y = image.height - newHeight;
      } else if (clipPosition === 'center-top') {
        x = (image.width - newWidth) / 2;
        y = 0;
      } else if (clipPosition === 'center-middle') {
        x = (image.width - newWidth) / 2;
        y = (image.height - newHeight) / 2;
      } else if (clipPosition === 'center-bottom') {
        x = (image.width - newWidth) / 2;
        y = image.height - newHeight;
      } else if (clipPosition === 'right-top') {
        x = image.width - newWidth;
        y = 0;
      } else if (clipPosition === 'right-middle') {
        x = image.width - newWidth;
        y = (image.height - newHeight) / 2;
      } else if (clipPosition === 'right-bottom') {
        x = image.width - newWidth;
        y = image.height - newHeight;
      } else if (clipPosition === 'scale') {
        x = 0;
        y = 0;
        newWidth = width;
        newHeight = height;
      } else {
        console.error(
            new Error('Unknown clip position property - ' + clipPosition)
        );
      }

      return {
        cropX: x,
        cropY: y,
        cropWidth: newWidth,
        cropHeight: newHeight,
        widthOriginal: size.width,
        heightOriginal: size.height,
      };
    },
    deselectObject() {
      this.transformer.nodes([]);
      this.transformerImage.nodes([]);
      this.selectedObject = null
    },
    deselectObjectByUser() {
      // if (this.workSpaceOneTextObjectEditingComputed) {
      //   return // Disable deselecting objects functionality
      // }
      this.deselectObject()
    },
    selectBackground() {
      // Deselect objects
      this.deselectObjectByUser()
      // Select the background object (without transformer)
      this.selectedObject = this.backgroundObject
    },
    createStageBackground() {
      // Get stage dimensions
      let stageWidth = this.stage.width();
      let stageHeight = this.stage.height();
      // Create background object
      this.stageBackground = new Konva.Rect({
        x: 0,
        y: 0,
        width: stageWidth,
        height: stageHeight,
        // fill: 'rgba(240,240,240,1)',
        draggable: false,
      });
      // add the shape to the layer
      this.layerCanvas.add(this.stageBackground);
    },
    createProductBackground(user_properties = {}) {
      let $vm = this
      let default_properties = {
        id: uuid.v4(),
        x: 0,
        y: 0,
        width: this.productWidth,
        height: this.productHeight,
        fill: 'rgba(255,255,255,1)',
        draggable: false,
        isBackground: true,
        isBackgroundColor: true,
      }
      // Merge user properties with the default properties.
      let properties = {
        ...default_properties,
        ...user_properties
      }
      // Create background object
      this.backgroundObject = new Konva.Rect(properties);
      // Clicks on product background deselect shapes
      this.backgroundObject.on('click tap', function () {
        $vm.selectBackground()

        // if ($vm.isDesktop) {
        //   // Select background
        //   $vm.selectBackground()
        // }
        // else {
        //   // Mobile: Deselect object, we need this so the user can see the 'create text/image/background' buttons
        //   $vm.deselectObjectByUser()
        // }
      });
      // add the shape to the layer
      this.productGroupDesign.add(this.backgroundObject);

      // Add placeholder images when flag is set
      if (this.placeholders && this.placeholders === 'all') {
        // Create background placeholders
        this.backgroundPlaceholders = this.createBackgroundPlaceholders()
        // Add to layer
        this.productGroupDesign.add(this.backgroundPlaceholders);
      }

      // Make sure the zIndex is all the way to the bottom
      this.productGroupDesign.moveToBottom()
    },
    createProductGroup() {
      // Create a nice shadow behind the product group
      this.productGroupShadow = new Konva.Rect({
        x: -99999, // Set it outside the viewport while loading
        y: -99999, // Set it outside the viewport while loading
        width: this.productWidth,
        height: this.productHeight,
        fill: 'rgba(255,0,0,0.4)',
        shadowColor: 'black',
        shadowBlur: 30,
        shadowOffset: { x: 8, y: 8 },
        shadowOpacity: 0.1,
      });
      this.layerCanvas.add(this.productGroupShadow);

      // Create the product group that will contain the design and holes
      this.productGroup = new Konva.Group({
        x: (this.stageWidth/2) - (this.productWidth/2),
        y: (this.stageHeight/2) - (this.productHeight/2),
        width: this.productWidth,
        height: this.productHeight,
      });

      // Create the product group that will contain the design
      this.productGroupDesign = new Konva.Group({
        name: 'productGroupDesign',
        clip: {
          x: 0,
          y: 0,
          width: this.productWidth,
          height: this.productHeight,
        },
        x: 0,
        y: 0,
        width: this.productWidth,
        height: this.productHeight,
      });
      // Add to product group
      this.productGroup.add(this.productGroupDesign)

      // Add to canvas holes
      if (this.productHoles) {
        this.productGroupHoles = this.createProductHoles()
        this.productGroup.add(this.productGroupHoles)
      }

      // add the group to the layer
      this.layerDesign.add(this.productGroup);
    },
    createProductHoles() {
      // Group that holds the holes
      let productGroupHoles = new Konva.Group({
        x: 0,
        y: 0,
        width: this.productWidth,
        height: this.productHeight,
      });
      // Set style for holes
      let hole = {
        radius: 5,
        fill: 'white',
        stroke: "rgba(0,0,0,0.3)",
        strokeWidth: 1,
      }
      let holeTopLeft = new Konva.Circle({
        x: 16,
        y: 16,
        ...hole
      })
      let holeTopRight = new Konva.Circle({
        x: this.productWidth - 16,
        y: 16,
        ...hole
      })
      let holeBottomRight = new Konva.Circle({
        x: this.productWidth - 16,
        y: this.productHeight - 16,
        ...hole
      })
      let holeBottomLeft = new Konva.Circle({
        x: 16,
        y: this.productHeight - 16,
        ...hole
      })
      productGroupHoles.add(holeTopLeft)
      productGroupHoles.add(holeTopRight)
      productGroupHoles.add(holeBottomRight)
      productGroupHoles.add(holeBottomLeft)

      return productGroupHoles
    },
    createBackgroundPlaceholders() {
      let $vm = this

      // Create group that contains the placeholders
      let backgroundPlaceholders = new Konva.Group({
        x: 0,
        y: 0,
        width: this.productWidth,
        height: this.productHeight,
        name: 'backgroundPlaceholders'
      });

      // Create placeholder object
      this.backgroundPlaceholderText = new Konva.Image({
        x: this.productWidth / 2 - 333,
        y: 35,
        width: 666,
        height: 159
      });
      // Setup image
      let imageText = new Image();
      imageText.onload = function() {
        $vm.backgroundPlaceholderText.image(imageText);
      };
      imageText.src = require(`../assets/background-placeholders/placeholder-text.png`)

      // Create placeholder object
      this.backgroundPlaceholderBackground = new Konva.Image({
        x: 35,
        y: this.productHeight - 35 - 359,
        width: 290,
        height: 359
      });
      // Setup image
      let imageBackground = new Image();
      imageBackground.onload = function() {
        $vm.backgroundPlaceholderBackground.image(imageBackground);
      };
      imageBackground.src = require(`../assets/background-placeholders/placeholder-background.png`)

      // Create placeholder object
      this.backgroundPlaceholderImage = new Konva.Image({
        x: this.productWidth - 35 - 290,
        y: this.productHeight - 35 - 282,
        width: 290,
        height: 282
      });
      // Setup image
      let imageImage = new Image();
      imageImage.onload = function() {
        $vm.backgroundPlaceholderImage.image(imageImage);
      };
      imageImage.src = require(`../assets/background-placeholders/placeholder-image.png`)

      // Create click events on placeholder objects
      this.backgroundPlaceholderText.on('click tap', function () {
        // Add text object
        $vm.addTextAndSelect()
      });
      this.backgroundPlaceholderBackground.on('click tap', function () {
        // Select background object
        $vm.selectBackground()
        // Open the upload dialog
        $vm.$refs['attributeEditor'].openUploadDialogEditBackground();
      });
      this.backgroundPlaceholderImage.on('click tap', function () {
        // Trigger show dialog on the 'Add image' button.
        $vm.createTempImageObjectToOpenAttributeEditor()
      });

      // Mouse over for placeholders
      this.backgroundPlaceholderText.on('mouseenter', function(){
        $vm.stage.container().style.cursor = 'pointer';
      });
      this.backgroundPlaceholderImage.on('mouseenter', function(){
        $vm.stage.container().style.cursor = 'pointer';
      });
      this.backgroundPlaceholderBackground.on('mouseenter', function(){
        $vm.stage.container().style.cursor = 'pointer';
      });
      this.backgroundPlaceholderText.on('mouseleave', function(){
        $vm.stage.container().style.cursor = 'default';
      });
      this.backgroundPlaceholderImage.on('mouseleave', function(){
        $vm.stage.container().style.cursor = 'default';
      });
      this.backgroundPlaceholderBackground.on('mouseleave', function(){
        $vm.stage.container().style.cursor = 'default';
      });

      // Add placeholder objects
      backgroundPlaceholders.add(this.backgroundPlaceholderText)
      backgroundPlaceholders.add(this.backgroundPlaceholderBackground)
      backgroundPlaceholders.add(this.backgroundPlaceholderImage)

      return backgroundPlaceholders
    },
    removeBackgroundPlaceholders() {
      if (this.backgroundPlaceholders) {
        // Destroy konva object
        this.backgroundPlaceholders.destroy()
        this.placeholders = 'none'
      }
    },
    removeBackgroundPlaceholderText() {
      if (this.backgroundPlaceholderText) {
        // Destroy konva object
        this.backgroundPlaceholderText.destroy()
        this.placeholders = 'none'
      }
    },
    removeBackgroundPlaceholderBackground() {
      if (this.backgroundPlaceholderBackground) {
        // Destroy konva object
        this.backgroundPlaceholderBackground.destroy()
        this.placeholders = 'none'
      }
    },
    removeBackgroundPlaceholderImage() {
      if (this.backgroundPlaceholderImage) {
        // Destroy konva object
        this.backgroundPlaceholderImage.destroy()
        this.placeholders = 'none'
      }
    },
    createCanvas() {
      let $vm = this;

      // Create stage
      this.stage = new Konva.Stage({
        container: 'canvas',   // id of container <div>
        width: this.stageWidth,
        height: this.stageHeight,
      })

      // Create background layer
      this.layerCanvas = new Konva.Layer({
        name: 'canvas'
      })
      // add the layer to the stage
      this.stage.add(this.layerCanvas);

      // // Create design layer
      this.layerDesign = new Konva.Layer({
        name: 'design'
      })
      // add the layer to the stage
      this.stage.add(this.layerDesign);

      // draw the layers
      this.layerCanvas.draw();
      this.layerDesign.draw();

      // Listen for all changes on the design layer and append a new version to the history
      this.layerDesign.on('draw', () => {
        $vm.updateHistory();
      });

      // Create stage background
      this.createStageBackground()

      // Create product group
      this.createProductGroup()

      // Create transformers
      this.createTransformer()
      this.createTransformerImage()

      let latestClick;
      let previousTargetId;

      // clicks should select/deselect shapes
      this.stage.on('click tap', function (e) {

        // Check if it's a double click/tap or single click/tap
        let clickType = 'single';
        let now = new Date().getTime();
        let timesince = now - latestClick;
        // Check if time is less than 600ms and if the target is the same as the previous target
        if ((timesince < 600) && (timesince > 0) && e.target.getAttr('id') === previousTargetId){
          // Double click/tap
          clickType = 'double';
        }
        previousTargetId = e.target.getAttr('id');
        latestClick = new Date().getTime();

        // if click on empty area - remove all selections
        if (e.target === $vm.stage) {
          $vm.selectBackground()

          // if ($vm.isDesktop) {
          //   // Select background
          //   $vm.selectBackground()
          // }
          // else {
          //   // Mobile: Deselect object, we need this so the user can see the 'create text/image/background' buttons
          //   $vm.deselectObjectByUser()
          // }
          return;
        }

        // do nothing if clicked NOT on our rectangles
        if (!e.target.getAttr('transformable')) {
          return;
        }

        // Click on images
        if (e.target.className === 'Image') {
          if (clickType === 'double') {
            // Open the upload dialog
            $vm.openEditImageUploadDialog();
          }
        }

        // Select object
        $vm.selectObject(e.target)

      });

      // Clicks on stage background deselect shapes
      this.stageBackground.on('click tap', function () {
        $vm.selectBackground()

        // if ($vm.isDesktop) {
        //   // Select background
        //   $vm.selectBackground()
        // }
        // else {
        //   // Mobile: Deselect object, we need this so the user can see the 'create text/image/background' buttons
        //   $vm.deselectObjectByUser()
        // }
      });

    },
    createTransformer() {
      // Create transformer
      this.transformer = new Konva.Transformer({
        flipEnabled: false,
        anchorSize: 25,
        anchorCornerRadius: 15,
        anchorFill: "#29A9E5",
        rotateAnchorOffset: 30,
        rotationSnaps: [0, 90, 180, 270],
        enabledAnchors: ['bottom-center', 'top-left', 'top-center', 'top-right', 'bottom-right', 'bottom-left'],
      });
      // Set transformer buttons
      this.setTransformerButtons(this.transformer)
      // Add transformer to layer
      this.layerDesign.add(this.transformer);
    },
    createTransformerImage() {
      // Create transformer
      this.transformerImage = new Konva.Transformer({
        flipEnabled: false,
        anchorSize: 25,
        anchorCornerRadius: 15,
        anchorFill: "#29A9E5",
        rotateAnchorOffset: 30,
        rotationSnaps: [0, 90, 180, 270],
        enabledAnchors: ['bottom-center', 'top-left', 'top-center', 'top-right', 'bottom-right', 'bottom-left'],
        keepRatio: false,
        boundBoxFunc: (oldBox, newBox) => {
          if (newBox.width < 10 || newBox.height < 10) {
            return oldBox;
          }
          return newBox;
        },
      });
      // Set transformer buttons
      this.setTransformerButtons(this.transformerImage)
      // The image transformer needs different settings for the crop to work
      this.transformerImage.keepRatio(false);
      // Add transformer to layer
      this.layerDesign.add(this.transformerImage);
    },
    setTransformerButtons(transformer) {
      let $vm = this

      for (let button in this.transformerButtons) {
        let shape = this.transformerButtons[button].shape;
        let selector = button.replace('_', '-');

        let iconColor = 'white'
        if (selector === 'bottom-center') {
          iconColor = "#ff2626"
        }

        let icon;
        if (selector === 'top-center') {
          // Create icon as image
          icon = new Konva.Image({
            width: 22,
            height: 22,
            offsetX: 5,
            offsetY: 5,
          });
          // Setup image
          let iconImage = new Image();
          iconImage.onload = function() {
            icon.image(iconImage);
          };
          iconImage.src = require(`../assets/icon-move.png`)
        }
        // else if (selector === 'bottom-center') {
        //   // Create icon as image
        //   icon = new Konva.Image({
        //     width: 22,
        //     height: 22,
        //     offsetX: 5,
        //     offsetY: 5,
        //   });
        //   // Setup image
        //   let iconImage = new Image();
        //   iconImage.onload = function() {
        //     icon.image(iconImage);
        //   };
        //   iconImage.src = require(`../assets/icon-trash.png`)
        // }
        else {
          icon = new Konva.Path({
            fill: iconColor,
            data: this.transformerButtons[button].path,
            x: shape.x() - 5.25,
            y: shape.y() - 5.25,
          });
        }
        icon.name(selector + '-icon')
        icon.position(shape.position());
        transformer.add(icon);

        // if (selector === 'bottom-center') {
        //   shape.listening(false);
        //   icon.on('click touchend', () => {
        //     // Get selected object
        //     let selectedObjectID = $vm.selectedObject.attrs.id
        //     // Remove object
        //     $vm.objectDestroy(selectedObjectID)
        //   })
        // }
        if (selector === 'top-center') {
          shape.listening(false);
          icon.on('pointerdown', (event) => {
            let selectedObject = $vm.selectedObject
            // Get mouse position
            this.dragStartX = event.evt.clientX
            this.dragStartY = event.evt.clientY
            this.dragScale = selectedObject.getAbsoluteScale()
            // Start dragging flag
            $vm.isDraggingTransformIcon = true
          })
        }
      }
    },
    /**
     * Updates the style of the transformer to make sure the icons are on the correct position
     */
    updateTransformer(transformer) {
      transformer.update();
      let enableAnchors = transformer.enabledAnchors();
      for (let button in this.transformerButtons) {
        let selector = button.replace('_', '-');
        let shape = transformer.findOne('.' + selector);
        let icon = transformer.findOne('.' + selector + '-icon');
        icon.position(shape.position());
        icon.x(icon.x() - 7.25);
        icon.y(icon.y() - 7.25);
        icon.scaleX(1.3)
        icon.scaleY(1.3)
        icon.show()
        this.layerDesign.batchDraw();

        // Hide icons when anchors are not enabled
        if (selector === 'top-left' && !enableAnchors.includes('top-left')) {
          icon.hide()
        }
        if (selector === 'top-center' && !enableAnchors.includes('top-center')) {
          icon.hide()
        }
        if (selector === 'top-right' && !enableAnchors.includes('top-right')) {
          icon.hide()
        }
        if (selector === 'bottom-left' && !enableAnchors.includes('bottom-left')) {
          icon.hide()
        }
        if (selector === 'bottom-right' && !enableAnchors.includes('bottom-right')) {
          icon.hide()
        }
        // if (selector === 'bottom-center' && !enableAnchors.includes('bottom-center')) {
        //   icon.hide()
        // }

      }
    },
    updateTransformerImage(transformer) {
      transformer.update();
      let enableAnchors = transformer.enabledAnchors();
      for (let button in this.transformerButtons) {
        let selector = button.replace('_', '-');
        let shape = transformer.findOne('.' + selector);
        let icon = transformer.findOne('.' + selector + '-icon');
        icon.position(shape.position());
        icon.x(icon.x() - 7.25);
        icon.y(icon.y() - 7.25);
        icon.scaleX(1.3)
        icon.scaleY(1.3)
        icon.show()
        this.layerDesign.batchDraw();

        // Hide icons when anchors are not enabled
        if (selector === 'top-left' && !enableAnchors.includes('top-left')) {
          icon.hide()
        }
        if (selector === 'top-center' && !enableAnchors.includes('top-center')) {
          icon.hide()
        }
        if (selector === 'top-right' && !enableAnchors.includes('top-right')) {
          icon.hide()
        }
        if (selector === 'bottom-left' && !enableAnchors.includes('bottom-left')) {
          icon.hide()
        }
        if (selector === 'bottom-right' && !enableAnchors.includes('bottom-right')) {
          icon.hide()
        }
        // if (selector === 'bottom-center' && !enableAnchors.includes('bottom-center')) {
        //   icon.hide()
        // }

      }
    },
    createNewDesign() {
      // Create the canvas
      this.createCanvas()
      // Create new default background
      this.createProductBackground()
      // Save the original design so the user reset to it
      this.sourceCodeOriginal = this.saveSourceCode();
    },
    async loadExistingDesign(designUUID) {

      // Fetch the design via AJAX
      // Simple GET request using fetch
      // GET request using fetch with async/await
      const response = await fetch(process.env.VUE_APP_API_BASEURL+"api/design/"+designUUID)
      // Show error when there is something wrong
      if (!response.ok) {
        this.$toast.error("Het ontwerp kon niet geladen worden. Foutcode: ["+response.status + "] "+response.statusText, {timeout: false});
        return
      }

      const data = await response.json();
      let sourceCode = data.source_code;

      // Save the original design so the user reset to it
      this.sourceCodeOriginal = sourceCode;

      // Set product width and height
      this.productWidth = sourceCode.productWidth
      this.productHeight = sourceCode.productHeight
      // Set flags
      this.backgroundLocked = sourceCode.backgroundLocked
      this.workSpaceOneTextObjectEditing = sourceCode.workSpaceOneTextObjectEditing

      // Create the canvas
      this.createCanvas()

      // Load existing design via source code
      await this.loadSourceCode(sourceCode)

      // Pre-select object
      this.preSelectObject()

    },
    async fontsReady() {
      let FontFaceObserver = require('fontfaceobserver');

      let amatic = new FontFaceObserver('Amatic SC');
      let amaticBold = new FontFaceObserver('Amatic SC', {weight: 'bold'});
      let bigShoulders = new FontFaceObserver('Big Shoulders Stencil Text');
      let bigShouldersBold = new FontFaceObserver('Big Shoulders Stencil Text', {weight: 'bold'});
      let cabinSketch = new FontFaceObserver('Cabin Sketch');
      let cabinSketchBold = new FontFaceObserver('Cabin Sketch', {weight: 'bold'});
      let caudex = new FontFaceObserver('Caudex');
      let caudexBold = new FontFaceObserver('Caudex', {weight: 'bold'});
      let fredoka = new FontFaceObserver('Fredoka');
      let fredokaBold = new FontFaceObserver('Fredoka', {weight: 'bold'});
      let goldman = new FontFaceObserver('Goldman');
      let goldmanBold = new FontFaceObserver('Goldman', {weight: 'bold'});
      let grandstander = new FontFaceObserver('Grandstander');
      let grandstanderBold = new FontFaceObserver('Grandstander', {weight: 'bold'});
      let kalam = new FontFaceObserver('Kalam');
      let kalamBold = new FontFaceObserver('Kalam', {weight: 'bold'});
      let lato = new FontFaceObserver('Lato');
      let latoBold = new FontFaceObserver('Lato', {weight: 'bold'});
      let montserrat = new FontFaceObserver('Montserrat');
      let montserratBold = new FontFaceObserver('Montserrat', {weight: 'bold'});
      let oleo = new FontFaceObserver('Oleo Script');
      let oleoBold = new FontFaceObserver('Oleo Script', {weight: 'bold'});
      let PTSerif = new FontFaceObserver('PT Serif');
      let PTSerifBold = new FontFaceObserver('PT Serif', {weight: 'bold'});
      let roboto = new FontFaceObserver('Roboto');
      let robotoBold = new FontFaceObserver('Roboto', {weight: 'bold'});
      let robotoMono = new FontFaceObserver('Roboto Mono');
      let robotoMonoBold = new FontFaceObserver('Roboto Mono', {weight: 'bold'});
      let robotoSlab = new FontFaceObserver('Roboto Slab');
      let robotoSlabBold = new FontFaceObserver('Roboto Slab', {weight: 'bold'});

      // Wait for all fonts to be loaded
      return Promise.all([
        amatic.load(),
        amaticBold.load(),
        bigShoulders.load(),
        bigShouldersBold.load(),
        cabinSketch.load(),
        cabinSketchBold.load(),
        caudex.load(),
        caudexBold.load(),
        fredoka.load(),
        fredokaBold.load(),
        goldman.load(),
        goldmanBold.load(),
        grandstander.load(),
        grandstanderBold.load(),
        kalam.load(),
        kalamBold.load(),
        lato.load(),
        latoBold.load(),
        montserrat.load(),
        montserratBold.load(),
        oleo.load(),
        oleoBold.load(),
        PTSerif.load(),
        PTSerifBold.load(),
        roboto.load(),
        robotoBold.load(),
        robotoMono.load(),
        robotoMonoBold.load(),
        robotoSlab.load(),
        robotoSlabBold.load(),
      ])

    },
    async preloadImages(sourceCode) {
      // Loop over all items
      return new Promise((resolve) => {
        if (!sourceCode.productDesign || !sourceCode.productDesign.length) {
          resolve()
        }
        // Count total amount of images
        let totalImages = 0
        let totalLoaded = 0
        // Loop over all elements to get total amount of images
        for (const element of sourceCode.productDesign) {
          if (element.className === 'Image') {
            totalImages++
          }
        }
        // No images found, resolve right away
        if (totalImages === 0) {
          resolve()
        }
        // Loop over all items to load them
        for (const element of sourceCode.productDesign) {
          if (element.className === 'Image') {
            this.preloadImage(element.attrs.src, function () {
              totalLoaded++;
              if (totalLoaded === totalImages) {
                // All images are loaded. Resolve promise.
                resolve()
              }
            });
          }
        }
      })
    },
    preloadImage(url, anImageLoadedCallback) {
      let img = new Image();
      img.onload = anImageLoadedCallback;
      img.onerror = anImageLoadedCallback;
      img.src = url;
    },
    fitProductGroupWithinCanvas() {
      let container = document.querySelector('#stage-parent');
      if (!container) {
        return
      }

      // Scale the canvas
      this.stage.width(this.stageWidth)
      this.stage.height(this.stageHeight)

      let scale = 1

      // Get product dimensions
      let productWidth = this.productWidth
      let productHeight = this.productHeight

      let maxWidth = this.stageWidth - this.productMargin
      let maxHeight = this.stageHeight - this.productMargin

      // Scale product down to make sure it fits within the canvas
      if (productWidth > maxWidth || productHeight > maxHeight) {
        scale = Math.min((maxWidth/productWidth),(maxHeight/productHeight));
      }

      // Responsive scale stage
      this.productGroup.scale({ x: scale, y: scale });
      this.productGroupShadow.scale({ x: scale, y: scale });
      // Set new X and Y with scaled
      let productGroupX = (this.stageWidth/2) - ((productWidth/2)*scale)
      let productGroupY = (this.stageHeight/2) - ((productHeight/2)*scale)
      if (!this.isDesktop) {
        productGroupY = 20; // Always show design at the top for mobile
      }
      this.productGroupShadow.x(productGroupX)
      this.productGroupShadow.y(productGroupY)
      this.productGroup.x(productGroupX)
      this.productGroup.y(productGroupY)

    },
    fitProductGroupWithinCanvasDebounced: _.debounce( function() {
      // Use debounce because the v-navigation-drawer has a transition. We need to wait before the transition has ended.
      this.fitProductGroupWithinCanvas()
      // Update transformers
      this.updateTransformer(this.transformer)
      this.updateTransformerImage(this.transformerImage)
    }, 50),
    exportCanvasToImage(holes = false) {
      // Deselect objects
      this.deselectObject()
      // Temporarily hide product holes when 'holes' flag is set to 'false'
      if (holes === false && this.productGroupHoles) {
        this.productGroupHoles.hide()
      }
      // Temporarily hide background placeholders
      if (this.backgroundPlaceholders) {
        this.backgroundPlaceholders.hide()
      }
      // Reset responsive scale stage
      this.productGroup.width(this.productWidth);
      this.productGroup.height(this.productHeight);
      this.productGroup.scale({ x: 1, y: 1 });
      // Create image of product
      let dataURL = this.stage.toDataURL({
        x: this.productGroup.x(),
        y: this.productGroup.y(),
        width: this.productWidth,
        height: this.productHeight,
        pixelRatio: 4
      });
      // Show product holes again
      if (this.productGroupHoles) {
        this.productGroupHoles.show()
      }
      // Show background placeholders
      if (this.backgroundPlaceholders) {
        this.backgroundPlaceholders.show()
      }
      // Re-apply the responsive stage scaling
      this.fitProductGroupWithinCanvas()
      // Return data
      return dataURL
    },
    async addDesignToCart() {
      // Set flag
      this.designIsSaving = true
      // Save the design
      const responseSaveDesign = await this.saveDesign()
      if (responseSaveDesign.ok) {
        // Add design to cart and redirect to cart
        window.location.href = process.env.VUE_APP_API_BASEURL+'commerce/add-to-cart-by-sku-and-go-to-cart/'+this.productVariationSKU+'?designUUID='+this.designUUID
      }
      else {
        // Reset flag
        this.designIsSaving = false
      }
    },
    async saveDesignToMyDesigns() {
      let $vm = this;
      // Set flag
      this.designIsSaving = true
      // Save the design
      const responseSaveDesign = await this.saveDesign()
      if (responseSaveDesign.ok) {
        // $vm.$toast.success("Uw ontwerp is opgeslagen in 'Mijn ontwerpen'.");
        $vm.dialogSavedDesign = true;
        // Set flag
        this.designIsSaving = false
      }
      else {
        // Set flag
        this.designIsSaving = false
      }
    },
    async saveDesign() {
      let $vm = this;
      // Export HD image of the canvas
      let image_export = this.exportCanvasToImage(false)
      // Get source code as string
      let source_code = JSON.stringify(this.saveSourceCode())
      // Save design to backend
      const responseSaveDesign = await fetch(process.env.VUE_APP_API_BASEURL+'api/design/'+this.designUUID, {
        method: 'PUT',
        credentials: "include", // Set to make sure the cookies are send with the request.
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.userCSRFToken,
        },
        body: JSON.stringify({
          source_code: source_code,
          image: image_export,
          product_id: this.productID,
          sku: this.productVariationSKU,
        })
      })
      // Show error when there is something wrong
      if (!responseSaveDesign.ok) {
        $vm.$toast.error("Het ontwerp kon niet opgeslagen worden. Foutcode: ["+responseSaveDesign.status + "] "+responseSaveDesign.statusText, {timeout: false});
        this.designIsSaving = false
      }
      else if (responseSaveDesign.ok) {
        return responseSaveDesign
      }
    },
    saveSourceCode() {
      let sourceCode = {}
      // Set canvas width and height
      sourceCode.productWidth = this.productWidth
      sourceCode.productHeight = this.productHeight
      // Set flags
      sourceCode.backgroundLocked = this.backgroundLocked
      sourceCode.workSpaceOneTextObjectEditing = this.workSpaceOneTextObjectEditing
      // Get the design JSON
      let designJSON = JSON.parse(this.productGroupDesign.toJSON())
      // Add design elements
      sourceCode.productDesign = designJSON.children
      // Return design as JSON
      return sourceCode
    },
    async loadSourceCode(sourceCode) {
      return new Promise((resolve) => {
        // Set flag (only do this the first time when it's still empty)
        if (this.placeholders === '' && sourceCode.backgroundPlaceholder) {
          this.placeholders = sourceCode.backgroundPlaceholder
        }

        // Count total amount of images
        let totalImages = 0
        let totalLoaded = 0
        // Loop over all elements to get total amount of images
        for (const element of sourceCode.productDesign) {
          if (element.className === 'Image') {
            totalImages++
          }
        }

        // No images found, resolve right away
        if (totalImages === 0) {
          resolve()
        }

        // Loop over all items and create them on the stage
        if (sourceCode.productDesign && sourceCode.productDesign.length) {
          for (const [zIndex, element] of sourceCode.productDesign.entries()) {
            if (element.attrs.isBackgroundColor) {
              // Create the background color
              this.createProductBackground(element)
            }
            else if (element.attrs.isBackgroundImage) {
              // Set default X and Y (they are removed when exporting when they are set to '0')
              element.attrs.x = element.attrs.x ? element.attrs.x : 0
              element.attrs.y = element.attrs.y ? element.attrs.y : 0
              // Create the background image
              this.loadAndAddExistingBackgroundImage(element, zIndex, function(){
                // Image has been loaded and added
                totalLoaded++
                // All images are loaded. Resolve promise.
                if (totalLoaded === totalImages) {
                  resolve()
                }
              })
            }
            else if (element.className === 'Text') {
              // Create text
              this.addText(element.attrs)
            }
            else if (element.className === 'Image') {
              // Copy the width and height properties from the 'myWidth' and 'myHeight' properties
              element.attrs.height = element.attrs.myHeight
              element.attrs.width = element.attrs.myWidth
              // Load the image
              this.loadAndAddExistingImage(element, zIndex, function(){
                // Image has been loaded and added
                totalLoaded++
                // All images are loaded. Resolve promise.
                if (totalLoaded === totalImages) {
                  resolve()
                }
              })
            }
          }
        }

      })
    },
    preSelectObject() {
      // Find KonvaJS text shapes
      let textObjects = this.stage.find('Text')
      // Find KonvaJS image shapes
      let imageObjects = this.stage.find(node => {
        // Only search objects in the productGroupDesign layer where the user design is at.
        let isProductDesignLayer = node.findAncestors('.productGroupDesign').length;
        if (!isProductDesignLayer) {
          return false;
        }
        // Check if object is selectable
        let isChangeableImage = node.getClassName() === 'Image'
        let isBackground = node.getAttr('isBackground')
        let isPlaceholder = node.findAncestors('.backgroundPlaceholders').length;

        return isChangeableImage && !isBackground && !isPlaceholder;
      })

      if (textObjects.length) {
        // Pre-select the first text object found
        this.selectObject(textObjects[0])
      }
      else if (imageObjects.length) {
        // Pre-select the first image object found
        this.selectObject(imageObjects[0])
      }
      else {
        // Pre-select the background object
        this.selectObject(this.backgroundObject)
      }

      // if (this.workSpaceOneTextObjectEditingComputed) {
      //   if (textObjects.length) {
      //     // Pre-select the first text object found
      //     this.selectObject(textObjects[0])
      //   }
      //   else if (imageObjects.length) {
      //     // Pre-select the first image object found
      //     this.selectObject(imageObjects[0])
      //   }
      // }
      // else {
      //   // Pre-select the background object
      //   this.selectObject(this.backgroundObject)
      // }

    },
    onCopySuccess() {
      let $vm = this;
      $vm.$toast.success("Gekopieerd");
    },
    onCopyError() {
      let $vm = this;
      $vm.$toast.error("Kon niet kopieren.");
    },
    imageCropped(image) {
      this.loadAndUpdateExistingImage({image: image, id: this.selectedObject.attrs.id});
    },
    openEditImageUploadDialog() {
      this.$refs['attributeEditor'].openUploadDialogEditImage();
    },
    resetRotation(object) {
      // Reset the rotation of a KonvaJS object
      object.rotation(0);
    },
    updateHistory: _.debounce( function() {
      if (this.historyLastAction === 'user-edit') {
        // Append new version to history when we are at the end of the history
        this.history.push(this.saveSourceCode())
        // Only keep the last 50 versions in the history
        if (this.history.length > this.historyMax) {
          this.history.shift()
        }
        // Update history index
        this.historyIndex = this.history.length - 1;
      }
      else if (this.historyLastAction === 'undo' || this.historyLastAction === 'redo') {
        // If the last action was an undo or redo, we don't want to append a new version to the history.
        // The next action will be a 'user-edit' and will append a new version to the history again.
        this.historyLastAction = 'user-edit'
      }
    }, 1000),
    resetDesign() {
      this.resetDesignDialog = false;
      // Recreate the canvas
      this.createCanvas();
      // Reset the design to the original state
      this.loadSourceCode(this.sourceCodeOriginal)
    },
    showResetDesignDialog() {
      this.resetDesignDialog = true;
    },
    undoHistory() {
      // Check if there is a previous version based on the history index
      if (this.historyIndex > 0) {
        // Decrease the history index
        this.historyIndex--
        this.historyLastAction = 'undo';
        // Recreate the canvas
        this.createCanvas();
        // Load the previous version
        this.loadSourceCode(this.history[this.historyIndex])
        // Deselect objects
        this.deselectObject();
        this.selectBackground();
      }
    },
    redoHistory() {
      // Check if there is a next version based on the history index
      if (this.historyIndex < this.history.length - 1) {
        // Increase the history index
        this.historyIndex++
        this.historyLastAction = 'redo';
        // Recreate the canvas
        this.createCanvas();
        // Load the next version
        this.loadSourceCode(this.history[this.historyIndex])
        // Deselect objects
        this.deselectObject();
        this.selectBackground();
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

// Import Google fonts
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Big+Shoulders+Stencil+Text:wght@400;700&family=Cabin+Sketch:wght@400;700&family=Caudex:wght@400;700&family=Fredoka:wght@400;700&family=Goldman:wght@400;700&family=Grandstander:wght@400;700&family=Kalam:wght@400;700&family=Lato:wght@400;700&family=Montserrat:wght@400;700&family=Oleo+Script:wght@400;700&family=PT+Serif:wght@400;700&family=Roboto+Mono:wght@400;700&family=Roboto+Slab:wght@400;700&family=Roboto:wght@400;700&display=swap');

//font-family: 'Amatic SC', cursive;
//font-family: 'Big Shoulders Stencil Display', cursive;
//font-family: 'Cabin Sketch', cursive;
//font-family: 'Caudex', serif;
//font-family: 'Fredoka', sans-serif;
//font-family: 'Goldman', cursive;
//font-family: 'Grandstander', cursive;
//font-family: 'Kalam', cursive;
//font-family: 'Lato', sans-serif;
//font-family: 'Montserrat', sans-serif;
//font-family: 'Oleo Script', cursive;
//font-family: 'PT Serif', serif;
//font-family: 'Roboto', sans-serif;
//font-family: 'Roboto Mono', monospace;
//font-family: 'Roboto Slab', serif;

.toolbar-title {
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    font-size: 15px !important;
  }
}
.v-btn {
  text-transform: unset !important;
}
// The Konva Canvas wrapper
.konvajs-content {
  margin: 0 auto;
}
#preview {
  padding: 30px 20px 20px 20px;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    padding: 40px 80px 80px 80px;
  }

  img {
    height: auto;
    width: 100%;

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      object-fit: contain;
      height: calc(100vh - 200px);
      width: 100%;
    }
  }
}
.main-wrapper {
  background: #e9e9e9;
}
.toolbar-title-logo {
  height: 24px;
  width: 123px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    height: 30px;
    width: 153px;
  }
}
.contextual-actions {
  display: flex;
  position: absolute;
  transform: translateX(-50%);
}
//.attribute-add-elements-desktop {
//  position: absolute;
//  top: 30px;
//  left: 15px;
//  z-index: 2;
//}

.icon-above-text .v-btn__content {
  flex-direction: column;
}

</style>
