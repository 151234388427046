<template>
  <div>

    <v-btn
        x-small
        @click="openCropDialog"
        class="icon-above-text"
        height="33"
    >
      <v-icon small>mdi-crop</v-icon>
      <span>Bijsnijden</span>
    </v-btn>

    <v-dialog v-model="cropDialog" width="auto">
      <v-card>
        <v-card-title>
          Afbeelding bijsnijden
        </v-card-title>

        <v-card-text class="pa-4">

          <template v-if="!saving">
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <cropper
                v-if="cropDialog && originalImage"
                class="cropper mb-4"
                ref="cropper"
                @ready="loading = false"
                :src="originalImage"
            />
            <v-btn @click="saveCrop" color="primary" class="mr-3">Toepassen</v-btn>
            <v-btn @click="hideDialog">Annuleren</v-btn>
          </template>

          <template v-if="saving">
            <v-progress-linear indeterminate></v-progress-linear>
          </template>

        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
  components: {
    Cropper,
  },
  props: {
    designUUID: {
      type: String,
      default: '',
    },
    originalImage: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      loading: true,
      saving: false,
      coordinates: {},
      cropDialog: false,
      croppedImage: '',
    }
  },
  watch: {
    cropDialog(newVal) {
      if (!newVal) {
        // Reset flags when dialog is closed
        this.loading = true;
        this.saving = false;
      }
    },
  },
  computed: {
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile
    },
  },
  methods: {
    showDialog() {
      // Show dialog
      this.cropDialog = true
    },
    hideDialog() {
      // Close the dialog
      this.cropDialog = false
    },
    openCropDialog() {
      this.showDialog()
    },
    async saveCrop() {
      this.saving = true;
      this.crop();
      // Upload new image
      let response = await this.uploadFile(this.croppedImage);
      // Get JSON response
      const responseJson = await response.json()
      // Emit the newly cropped image
      this.$emit('image-cropped', responseJson)
      // Hide dialog
      this.hideDialog();
    },
    crop() {
      const { coordinates, canvas, } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image, that can be used
      // as src for <img/> to preview result
      this.croppedImage = canvas.toDataURL();
    },
    async uploadFile(base64String) {
      let extension = base64String.split(';')[0].split('/')[1];
      // Upload file to backend
      const response = await fetch(process.env.VUE_APP_API_BASEURL+'api/design/'+this.designUUID+'/file-upload', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filename: 'cropped-image.' + extension,
          filebase64: base64String,
        })
      });
      return response
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
/*
	It may be necessary to set limits on the size of the cropper, otherwise the cropper image will try to fill all the available space.
*/
.cropper {
  height: 600px;
  width: 600px;
  background: #DDD;

  // On mobile make the cropper less height
  @media (max-width: 600px) {
    height: 400px;
    width: auto;
  }

}
</style>
