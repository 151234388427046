import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    objects: [],
    selectedObjects: []
};

const getters = {
    getObjects: (state) => {
        return state.objects
    },
};

const mutations = {
    objectsAdd(state, object) {
        state.objects.push(object)
    },
    objectsUpdate(state, object) {
        let objectIndex = state.selectedObjects.findIndex(e => e.id === object.id);
        // state.objects[objectIndex] = object
        Vue.set(state.objects, objectIndex, object)
    },
    selectionSelectSingle(state, object) {
        state.selectedObjects = [object]
    },
    selectionAdd(state, object) {
        state.selectedObjects.push(object)
    },
    selectionRemove(state, object) {
        let objectIndex = state.selectedObjects.findIndex(e => e.id === object.id);
        state.selectedObjects.splice(objectIndex, 1);
    },
    selectionClear() {
        state.selectedObjects = []
    }
}

export default new Vuex.Store({
    state,
    getters,
    mutations,
})