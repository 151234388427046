import Vue from 'vue';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
    position: "top-center",
    timeout: 4000,
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true,
    hideProgressBar: true,
    // Prevents toasts with the same content from appearing simultaneously, discarding duplicates
    filterBeforeCreate: (toast, toasts) => {
        if (toasts.filter(t => t.content === toast.content).length !== 0) {
            // Returning false discards the toast
            return false;
        }
        // You can modify the toast if you want
        return toast;
    }
}

Vue.use(Toast, options);

export default Toast